import React from "react";
import "./ServiceStyle.css";
import { Link } from "react-router-dom";

function Service() {
  const handleClick = () => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
  };
  return (
    <div>
      {/* ---------------Section One--------------- */}
      <div className="ServiceTopScreen">
        <div className="ServiceTopScreenDiv1">
          <p className="Workpara">Work with GrowCraft Solution</p>
          <hr />
          <h1 className="Workh1">
            Innovative Software Solutions Tailored for Your Success
          </h1>
        </div>
        <div className="ServiceTopScreenDiv2">
          <div className="ScreentopImageDiv">
            <img
              src="./Assets/Services/Workwith.png"
              height={350}
              className="ScreentopImage"
            />
          </div>
        </div>
      </div>
      {/* ---------------Section Two--------------- */}
      <div className="ServiceTopScreen2">
        <div className="ExploreLine"></div>
        <h1 className="Exploreh1">Explore Our Services</h1>
        <p className="ExploreText">
          We provide reliable service to our client that is unbeatable
        </p>
        <div className="UIUXDesigning">
          <div className="UIUXDesigningDiv1">
            <text className="UIUXDesigningText">01</text>
            <h2>UI/UX Designing</h2>
            <p>
              The best software has both a strong back-end and a good front-end.
              We've got the tools and expertise to assist you in creating an
              intuitive and engaging user experience that your customers will
              love and your rivals will envy. Our UX/UI Design. User interface
              and user experience design for websites and applications. Services
              include
            </p>
            <li>
              {" "}
              <text className="DesignText">Design Workshops</text>{" "}
            </li>
            <li>
              {" "}
              <text className="DesignText">UX & UI Consulting</text>
            </li>
            <li>
              {" "}
              <text className="DesignText">Creative Direction</text>
            </li>
            <li>
              {" "}
              <text className="DesignText">Prototyping</text>
            </li>
            <Link to="/UXUI" onClick={handleClick}>
              <button className="readmorebutton">Read More</button>
            </Link>
          </div>
          <div className="UIUXDesigningDiv2">
            <img
              src="./Assets/Services/UIUXDesigning.png"
              height={300}
              className="UIUXDesigningImage"
            />
          </div>
        </div>
        <div className="MobileApp">
          <div className="MobileAppDiv">
            <img src="./Assets/Services/FullStack.png" height={300} />
          </div>
          <div className="UIUXDesigningDiv1 UIUXDesigningDiv11">
            <text className="UIUXDesigningText">02</text>
            <h2>Mobile App Developmentt</h2>
            <p>
              Full-stack development refers to the practice of being proficient
              in both frontend and backend technologies, enabling a developer to
              work on all aspects of a web application. A full-stack developer
              has expertise in both client-side (frontend) and server-side
              (backend) development, as well as the ability to work with
              databases.
            </p>
            <Link to="/MobileApp" onClick={handleClick}>
              <button className="readmorebutton">Read More</button>
            </Link>
          </div>
        </div>
        <div className="UIUXDesigning">
          <div className="UIUXDesigningDiv1">
            <text className="UIUXDesigningText">03</text>
            <h2>Web Development</h2>
            <p>
              Web development refers to the process of creating and maintaining
              websites or web applications. It involves a combination of
              frontend and backend development, ensuring that websites are
              visually appealing, user-friendly, and functionally robust.
              Developers need to stay updated on the latest trends, best
              practices, and emerging technologies to create modern, efficient,
              and secure web solutions. Design and development of websites using
              various technologies and frameworks.
            </p>
            <Link to="/WebDevelopment" onClick={handleClick}>
              <button className="readmorebutton">Read More</button>
            </Link>
          </div>
          <div className="UIUXDesigningDiv2">
            <img src="./Assets/Services/WebDevelopment.png" height={300} />
          </div>
        </div>
        <div className="MobileApp">
          <div>
            <img src="./Assets/Services/FullStack.png" height={300} />
          </div>
          <div className="UIUXDesigningDiv1 UIUXDesigningDiv11">
            <text className="UIUXDesigningText">04</text>
            <h2>E-commerce Development</h2>
            <p>
              E-commerce development involves creating and optimizing online
              platforms for buying and selling goods or services. It encompasses
              various aspects, such as website design, secure payment
              integration, inventory management, and user-friendly interfaces.
              E-commerce developers use technologies like HTML, CSS, and
              JavaScript, along with platforms like Magento, WooCommerce, or
              Shopify, to build robust online stores.
            </p>
            <Link to="/Ecommerce" onClick={handleClick}>
              <button className="readmorebutton">Read More</button>
            </Link>
          </div>
        </div>
        <div className="UIUXDesigning">
          <div className="UIUXDesigningDiv1">
            <text className="UIUXDesigningText">05</text>
            <h2>Full Stack Development</h2>
            <p>
              Full-stack development refers to the practice of being proficient
              in both frontend and backend technologies, enabling a developer to
              work on all aspects of a web application. A full-stack developer
              has expertise in both client-side (frontend) and server-side
              (backend) development, as well as the ability to work with
              databases.
            </p>
            <Link to="/FullStack" onClick={handleClick}>
              <button className="readmorebutton">Read More</button>
            </Link>
          </div>
          <div className="UIUXDesigningDiv2">
            <img src="./Assets/Services/FullStack.png" height={300} />
          </div>
        </div>
        <div>
          <h4>Let's build great things together!</h4>
          <p>
            Fill out this form and one of our client success managers will
            contact you within 24 hours. We have notifications set to make sure
            your message is received.
          </p>
          <Link to="/Contact" onClick={handleClick}>
            <button className="contactusbtn">Contact Us</button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Service;
