import React, { useState } from "react";
import "./ProjectStyle.css";
import { Link } from "react-router-dom";

function Project() {
  const projects = [
    {
      id: 1,
      image: "./Assets/OurProjects/DontTouchMyApp.png",
      title: "Don’t Touch My App",
      body: "Don't Touch My Phone is a mobile application designed to protect your privacy and security by preventing unauthorized access to your device. With features like intruder detection and alarm systems, it notifies you if someone tries to tamper with or access your phone without permission. This app serves as a handy tool for safeguarding personal information and maintaining privacy in various settings.",
    },
    {
      id: 2,
      image: "./Assets/OurProjects/FileRecoveryApp.png",
      title: "File Recovery App",
      body: "A File Recovery App is a valuable tool designed to retrieve lost or deleted files. Whether due to accidental deletion, formatting, or system errors, these apps employ advanced algorithms to scan the storage media and recover files that may otherwise seem in accessible.They typically offer user-friendly interfaces and customizable search options, allowing users to specify file types or locations to expedite the recovery process. File Recovery Apps serve as a lifeline for users seeking to recover important documents, photos, videos, or other digital assets.",
    },
    {
      id: 3,
      image: "./Assets/OurProjects/PrankSoundApp.png",
      title: "Prank Sound App",
      body: "A Prank Sound App is a playful tool that adds entertainment to social gatherings or light-hearted moments by providing a collection of amusing or unexpected sounds. From funny noises to realistic sound effects, these apps offer a wide range of options to surprise and amuse friends or family members. Prank Sound Apps are perfect for practical jokes, harmless pranks, or simply adding laughter to everyday situations, making them a popular choice for those who enjoy a good laugh.",
    },
    {
      id: 4,
      image: "./Assets/OurProjects/BloodSugarApp.png",
      title: "Blood Sugar App",
      body: "A Blood Sugar App is a digital tool designed to help individuals monitor and manage their blood glucose levels. These apps typically allow users to input their blood sugar readings manually or automatically through compatible glucose meters or sensors. They often provide features like trend analysis, customizable reminders for medication or testing, and the ability to track factors such as diet, exercise, and medication intake. Some advanced apps may offer insights based on data trends or even connect users with healthcare professionals for remote monitoring or consultations",
    },
    {
      id: 5,
      image: "./Assets/OurProjects/CasttoTVApp.png",
      title: "Cast to TV App",
      body: "A Cast to TV App enables users to stream or 'cast' media content from their smartphones, tablets, or computers directly to their television screens. These apps typically utilize wireless technologies such as Wi-Fi or Bluetooth to establish a connection between the casting device and the TV. Users can then select videos, photos, music, or even entire applications from their device and display them on a larger screen for a more immersive viewing experience.",
    },
    {
      id: 6,
      image: "./Assets/OurProjects/TextScannerApp.png",
      title: "Text Scanner App",
      body: "A Text Scanner App is a convenient tool that allows users to extract text from physical documents, images, or any other visual content captured by their smartphone camera. These apps utilize Optical Character Recognition (OCR) technology to recognize and convert text within images into editable and searchable digital formats.Text Scanner Apps are valuable for digitizing physical documents, extracting information from printed materials, or converting handwritten notes into editable text, thus enhancing productivity and accessibility in everyday tasks.",
    },
    {
      id: 7,
      image: "./Assets/OurProjects/DrivingLicenseApp.png",
      title: "Driving License App",
      body: "A Driving License App is a digital application that provides a convenient and accessible platform for individuals to manage their driver's license information. These apps typically offer features such as digital copies of the license, renewal reminders, and access to driving records. Users can store their license details securely within the app, eliminating the need to carry a physical copy.",
    },
    {
      id: 8,
      image: "./Assets/OurProjects/PhotoVaultApp.png",
      title: "Photo Vault App",
      body: "A Photo Vault App is a privacy-focused tool designed to securely store and protect personal photos and videos on mobile devices. These apps typically offer features such as password protection, biometric authentication, and encryption to safeguard sensitive content from unauthorized access. Users can organize their media files within the vault, ensuring that they remain private and hidden from prying eyes. Additionally, some Photo Vault Apps may offer decoy modes or stealth features to further enhance security and prevent detection.",
    },
  ];
  const [currentSlide, setCurrentSlide] = useState(0);

  const prevSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? projects.length - 1 : prevSlide - 1
    );
  };

  const nextSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === projects.length - 1 ? 0 : prevSlide + 1
    );
  };
  const handleProjectClick = () => {
    window.open("https://play.google.com/store/apps/dev?id=5043097689055528565&hl=en_US", "_blank");
  };
  return (
    <div>
      {/* ---------------Section One--------------- */}
      <div className="OurProjectTopScreen">
        <div className="OurProjectTopScreenDiv1">
          <h1 className="OurProjectTopScreenh1">
            creating digital product bands & experience
          </h1>
          <p className="OurProjectTopScreenpara">
            Crafting compelling digital product brands requires a deep
            understanding of user needs and market trends. Establish a cohesive
            visual identity, incorporating captivating logos and consistent
            design elements. Prioritize user experience with intuitive
            interfaces, responsive design, and seamless navigation.
          </p>
        </div>
        <div className="OurProjectTopScreenDiv2">
          <div className="ScreentopImageDiv">
            <img src="./Assets/OurProjects/OurProjectIcon.png" height={350} className="ScreentopImageImage"/>
          </div>
        </div>
      </div>
      {/* ---------------Section Two--------------- */}
      <div className="OurProjectTopScreen2">
        <div className="ExploreLine"></div>
        <h1 className="Exploreh1">Our Recent Projects</h1>
        <p className="ExploreText">
          Our recent project seamlessly blends innovative design with
          cutting-edge technology, delivering a user-centric solution. With
          meticulous attention to detail, we tackled challenges and incorporated
          client feedback for optimal results. This project exemplifies our
          commitment to excellence, pushing boundaries to create impactful and
          successful digital experiences.
        </p>

        <div className="ProjectshowDiv">
          {projects.map((item, index) => (
            <div
              className="slide"
              key={index}
              style={{ display: index === currentSlide ? "block" : "none" }}
            >
              <Link onClick={handleProjectClick}>
              <img src={item.image} height={200}  width={500} className="ProjectshowImage"/>
              </Link>
              <h3 className="ProjectshowTitle">{item.title}</h3>
              <p className="ProjectshowBody">{item.body}</p>
            </div>
          ))}

          <div className="prev2" onClick={prevSlide}>
            &#10094;
          </div>
          <div className="next2" onClick={nextSlide}>
            &#10095;
          </div>
          <div className="dotDiv">
            {projects.map((item, index) => (
              <span
                key={index}
                className={index === currentSlide ? "dot active" : "dot"}
                onClick={() => setCurrentSlide(index)}
              ></span>
            ))}
          </div>
        </div>
      </div>
      {/* ---------------Section Three--------------- */}
      <div className="OurProjectTopScree3">
        <div>
          <img
            src="./Assets/OurProjects/Dot1.png"
            height={80}
            className="dot1"
          />
          <div className="TeamworkDiv">
            <img
              src="./Assets/OurProjects/TeamMetting.png"
              className="TeamMeetingImage"
            />
          </div>
          <img
            src="./Assets/OurProjects/Dot2.png"
            height={80}
            className="dot2"
          />
        </div>
      </div>
    </div>
  );
}

export default Project;
