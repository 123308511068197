import React, { useState } from "react";
import "./HomeStyle.css";
import { Link } from "react-router-dom";

function Home() {
  
  const data = [
    {
      id: 1,
      image: "./Assets/Home/UIUXDesign.png",
      title: "UI / UX  Design",
      body: "Build the product you need on time with an experienced team that uses a clear and effective design.",
      link: "/UXUI",
    },
    {
      id: 2,
      image: "./Assets/Home/MobileApp.png",
      title: "Mobile App Development",
      body: "Create complex enterprise software, ensure reliable software integration, modernize your legacy system.",
      link: "/MobileApp",
    },
    {
      id: 3,
      image: "./Assets/Home/WebDevelopment.png",
      title: "Web Development",
      body: "We carry more than just good coding skills. Our experience makes us stand out from other web development",
      link: "/WebDevelopment",
    },
    {
      id: 4,
      image: "./Assets/Home/FullStack.png",
      title: "Full Stack  Development",
      body: "We Full-stack development refers to the practice of being proficient in both frontend and backend technologies.",
      link: "/FullStack",
    },
    {
      id: 5,
      image: "./Assets/Home/ECommerce.png",
      title: "E-Commerce Development",
      body: "E-commerce development involves creating and optimizing online platforms for buying and selling goods or services. ",
      link: "/Ecommerce",
    },
  ];
  const data1 = [
    {
      id: 1,
      image: "./Assets/Home/Android.png",
      title: "Android",
    },
    {
      id: 2,
      image: "./Assets/Home/Reactjs.png",
      title: "React JS",
    },
    {
      id: 3,
      image: "./Assets/Home/ReactNative.png",
      title: "React Native",
    },
    {
      id: 4,
      image: "./Assets/Home/Flutter.png",
      title: "Flutter",
    },
    {
      id: 5,
      image: "./Assets/Home/responsiveWeb.png",
      title: "Responsive Web Design",
    },
    {
      id: 6,
      image: "./Assets/Home/Wordpress.png",
      title: "Wordpress",
    },
    {
      id: 7,
      image: "./Assets/Home/AdobeIllustrator.png",
      title: "Adobe Illustrator",
    },
    {
      id: 8,
      image: "./Assets/Home/Photoshop.png",
      title: "Photoshop",
    },
    {
      id: 9,
      image: "./Assets/Home/AdobeXD.png",
      title: "Adobe XD",
    },
    {
      id: 10,
      image: "./Assets/Home/IOS.png",
      title: "IOS",
    },
    {
      id: 11,
      image: "./Assets/Home/UIUX.png",
      title: "UI/UX Design",
    },
    {
      id: 12,
      image: "./Assets/Home/DigitalMarketing.png",
      title: "Digital Marketing",
    },
  ];
  const data2 = [
    {
      id: 1,
      image: "./Assets/Home/Clientserved.png",
      number: "8*",
      title: "Client served",
    },
    {
      id: 2,
      image: "./Assets/Home/ProjectCompleted.png",
      number: "20*",
      title: "Project Completed",
    },
    {
      id: 3,
      image: "./Assets/Home/ProjectSuccess.png",
      number: "15%",
      title: "Project Success",
    },
    {
      id: 4,
      image: "./Assets/Home/Revenuefromourproduct.png",
      number: "10M",
      title: "Revenue from our product",
    },
  ];
  const data3 = [
    {
      id: 1,
      image: "./Assets/Home/ProvideTheExpertise.png",
      title: "Provide The Expertise",
      body: "You can rely on our team to produce the product you are looking for across a range of platforms and devices.",
    },
    {
      id: 2,
      image: "./Assets/Home/TrustOurProfessionals.png",
      title: "Trust Our Professionals",
      body: "We will complete your project according to your specifications. We  can help if you feel trapped by your current developer.",
    },
    {
      id: 3,
      image: "./Assets/Home/ProvidingAllServices.png",
      title: "Providing All Services",
      body: "Our team will work with you to define scope of work and ensure it is fulfilled, make changes according to requirement.",
    },
  ];
  const employees = [
    {
      id: 1,
      name: "Sanjay Khangora",
      role: "As. Software Developer",
      photo: "./Assets/CompanyEmployeePic/SanjayKhangora.jpg",
    },
    {
      id: 2,
      name: "Suraj Kushwaha",
      role: "React Developer",
      photo: "./Assets/CompanyEmployeePic/SurajKushwaha.jpeg",
    },
    {
      id: 3,
      name: "Jayshree Solanki",
      role: "Sr. React Native Developer",
      photo: "./Assets/CompanyEmployeePic/JayshreeSolanki.jpg",
    },
    {
      id: 4,
      name: "Kimi Vyas",
      role: "Sr. Android Developer",
      photo: "./Assets/CompanyEmployeePic/KimiVyas.jpg",
    },
    {
      id: 5,
      name: "Rehan Mirza",
      role: "Android Developer",
      photo: "./Assets/CompanyEmployeePic/RehanMirza1.jpeg",
    },
    {
      id: 6,
      name: "Akash Chouby",
      role: "Android Developer",
      photo: "./Assets/CompanyEmployeePic/AkashChoubey.jpg",
    },
    {
      id: 7,
      name: "Sonu Ahirwar",
      role: "UI / UX Designer",
      photo: "./Assets/CompanyEmployeePic/SonuAhirwar.jpg",
    },
    {
      id: 8,
      name: "Vijay Hencha",
      role: "UI / UX Designer",
      photo: "./Assets/CompanyEmployeePic/VijayHench.jpg",
    },
    {
      id: 9,
      name: "Jitendra Sisodiya",
      role: "UI / UX Designer",
      photo: "./Assets/CompanyEmployeePic/JitendraSisodiya.jpg",
    },
    {
      id: 10,
      name: "Deeksha Manjhi",
      role: "Sr. UI / UX Designer",
      photo: "./Assets/CompanyEmployeePic/DeekshaManjhi.jpg",
    },
    {
      id: 11,
      name: "Shraddha Ahirwar",
      role: "Content Writer",
      photo: "./Assets/CompanyEmployeePic/shraddhaAhirwar.jpg",
    },
    {
      id: 12,
      name: "Amar Ahirwar",
      role: "UI / UX Designer",
      photo: "./Assets/CompanyEmployeePic/AmarAhirwar.jpeg",
    },
  ];
  const expert = [
    {
      id: 1,
      image: "./Assets/CompanyEmployeePic/Expert/ArvindAhirwar.png",
      name: "Arvind Ahirwar",
      role: "CEO",
      linkedin:"https://www.linkedin.com/in/arvind-choudhary-87194627b/",
      twitter:"https://twitter.com/ArvindA78168040",
      instagram:"https://www.instagram.com/arvindchoudhary43/"
    },
    {
      id: 2,
      image: "./Assets/CompanyEmployeePic/Expert/DharmendraAhirwar.jpeg",
      name: "Dharmendra Ahirwar",
      role: "CTO",
      linkedin: "https://www.linkedin.com/in/dharmendra-choudhary20/",
      twitter: "https://twitter.com/Dharmendra20024",
      instagram:"https://www.instagram.com/dharmendra_choudhary20/"
    },
    {
      id: 3,
      image: "./Assets/CompanyEmployeePic/Expert/DeepchandAhirwar.jpeg",
      name: "Deepchand Ahirwar",
      role: "Product Manager",
      linkedin:"https://www.linkedin.com/in/deepchand-ahirwar-1b588b141?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
      twitter:"https://x.com/deep_bodh_07?t=oHECfqhr24y4-UjOYm1YcA&s=09",
      instagram:"https://www.instagram.com/deep___bodh111/"
    },
  ];

  const [scrollIndex, setScrollIndex] = React.useState(0);

  const scrollRight = () => {
    setScrollIndex((prevIndex) => (prevIndex + 1) % employees.length);
  };

  const scrollLeft = () => {
    setScrollIndex(
      (prevIndex) => (prevIndex - 1 + employees.length) % employees.length
    );
  };

  const handleClick = () => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
  };

  const handleLinkedInClick = (linkedin) => {
    window.open(linkedin, "_blank");
  };

  const handleTwitterClick = (Twitter) => {
    window.open(Twitter, "_blank");
  };

  const handleInstagramClick = (instagram) =>{
    window.open(instagram, "_blank");
  }
  return (
    <div>
      {/* ---------------Section One--------------- */}
      <div className="HomeTopScreen">
        <div className="HomeTopCircle"></div>
        <div style={{ float: "revert" }}>
          <div className="HomeBody">
            <div>
              <div className="HomeBodyTextdiv">
                <p className="HomeBodyText">We Build Software </p>
                <p className="HomeBodyText1">Solutions</p>
              </div>
              <p className="HomeBodyText2">
                There are many variations of passages of but the majority
                <br /> have suffered alteration, by injected{" "}
              </p>
              <div className="HomeBodyButtongrid">
                <Link to="/" className="HomeBodyButton" onClick={handleClick}>
                  <text className="HomeBodyButtonText">Get Started</text>
                </Link>
                <Link
                  to="/Contact"
                  className="HomeBodyButton1"
                  onClick={handleClick}
                >
                  <text className="HomeBodyButtonText1">Contact Us</text>
                </Link>
              </div>
              <div>
                <p className="HomeBodyTrusted">Trusted By</p>
                <div className="HomeBodyTrustedDiv">
                  <div className="HomeBodyTrustedContent">
                    <img src="./Assets/Home/Skype.png" height={30} />
                  </div>
                  <div className="HomeBodyTrustedContent">
                    <img src="./Assets/Home/Microsoft.png" height={25} />
                  </div>
                  <div className="HomeBodyTrustedContent">
                    <img src="./Assets/Home/Google.png" height={25} />
                  </div>
                  <div className="HomeBodyTrustedContent">
                    <img src="./Assets/Home/Linkedin.png" height={30} />
                  </div>
                </div>
              </div>
            </div>
            <div className="imageIconImageDiv">
              <img
                src="./Assets/Home/HomeIcon.png"
                className="imageIconImage"
                height={350}
                width={450}
              />
            </div>
          </div>
          <div className="HomeBottomCircle"></div>
        </div>
      </div>
      {/* ---------------Section Two--------------- */}
      <div className="HomeTopScreen2">
        <div className="HomeServiceSolveTop">
          <div>
            <p className="HomeServiceSolveTopText">
              Our Services Solve any problem
            </p>
          </div>
          <div>
            <p className="HomeServiceSolveTopText1">
              At GrowCraft Solutions, our services are designed to solve any
              problem our clients may encounter. Whether it's streamlining
              processes, optimizing efficiency, or overcoming technical hurdles,
              we have the expertise and resources to deliver effective
              solutions.{" "}
            </p>
          </div>
        </div>
        <div className="scrollcontant">
          {data.map((item, index) => {
            return (
              <div key={index} className="UXUIDiv">
                <img src={item.image} alt={item.title} height={50} />
                <h2 className="UXUIh2">{item.title}</h2>
                <p className="UXUIBody">{item.body}</p>
                <Link to={item.link} onClick={handleClick}>
                  <img
                    src="./Assets/Home/ArrowRight.png"
                    height={10}
                    // style={{
                    //   marginTop:
                    //     item.title == "UI / UX  Design"
                    //       ? 50
                    //       : item.title == "Web Development"
                    //       ? 50
                    //       : 0,
                    // }}
                    className="UXUIBackImage"
                  />
                </Link>
              </div>
            );
          })}
        </div>
      </div>
      {/* ---------------Section Three--------------- */}
      <div className="HomeTopScreen3">
        <hr className="Homehr1" />
        <div className="OurDomain">
          <h2 className="OurDomainText">Our Domain Expertise</h2>
          <p className="OurDomainText1">
            Exceptional Attributes that Differentiate GrowCraft from Competitors
          </p>
        </div>
        <div className="data1Div">
          {data1.map((item, index) => {
            return (
              <div className="data1Icon" key={index}>
                <img src={item.image} height={60} className="data1IconImage" />
                <p className="data1IconText">{item.title}</p>
              </div>
            );
          })}
        </div>
      </div>
      {/* ---------------Section Four--------------- */}
      <div
        className="HomeTopScreen4"
        style={{ backgroundImage: "url('./Assets/Home/HomeTopScreen4.jpeg')" }}
      >
        <div className="Hometop4Map">
          {data2.map((item, index) => {
            return (
              <div key={index}>
                <img
                  src={item.image}
                  height={50}
                  className="Hometop4MapImage"
                />
                <h1 className="Hometop4Maph1">{item.number}</h1>
                <p className="Hometop4MapText">{item.title}</p>
              </div>
            );
          })}
        </div>
      </div>
      {/* ---------------Section Five--------------- */}
      <div className="HomeTopScreen5">
        <hr className="Homehr1" />
        <div className="OurDomain">
          <h5>Why customers love</h5>
          <h2>working with us</h2>
          <div className="whycustomerContant">
            <img
              src="./Assets/Home/querts1.png"
              height={10}
              style={{ float: "left" }}
            />
            <p className="whycustomerContant1">
              Without any doubt I recommend Alcaline Solutions as one of the
              best web design and digital marketing agencies. One of the best
              agencies I’ve came across so far. Wouldn’t be hesitated to
              introduce their work to someone else.
            </p>
            <img
              src="./Assets/Home/querts2.png"
              height={10}
              style={{ float: "right" }}
            />
          </div>

          <div className="employee-scroller">
            <div className="scroll-container">
              {[...employees, ...employees]
                .slice(scrollIndex, scrollIndex + 5)
                .map((employee, index) => (
                  <div
                    className={`employee ${index === 2 ? "center" : ""}`}
                    key={employee.id}
                  >
                    <img src={employee.photo} alt={employee.name} />
                    <h6 className="employeeh6">{employee.role}</h6>
                    <p className="employeeText">{employee.name}</p>
                  </div>
                ))}
            </div>
            <button className="scroll-button left" onClick={scrollLeft}>
              &lt;
            </button>
            <button className="scroll-button right" onClick={scrollRight}>
              &gt;
            </button>
          </div>
        </div>
      </div>
      {/* ---------------Section Six--------------- */}
      <div
        className="HomeTopScreen6"
        style={{ backgroundImage: "url('./Assets/Home/HomeTopScreen6.png')" }}
      >
        <h1 className="Somedistinguishing">
          Some distinguishing features that set GrowCraft apart from others.
        </h1>
        <p className="Somedistinguishing1">
          Exceptional Attributes that Differentiate GrowCraft from Competitors
        </p>
        <div className="ProvideExpertise">
          {data3.map((item, index) => {
            return (
              <div className="ProvideExpertisemap" key={index}>
                <div className="ProvideExpertiseTop">
                  <div className="ProvideExpertiseTopdesign1"></div>
                  <div className="ProvideExpertiseTopdesign2"></div>
                </div>
                <div className="ProvideExpertiseContantDiv">
                  <img
                    src={item.image}
                    height={40}
                    className="ProvideExpertiseImage"
                  />
                  <h5 className="ProvideExpertiseh5">{item.title}</h5>
                  <p className="ProvideExpertiseBody">{item.body}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {/* ---------------Section Seven--------------- */}
      <div className="HomeTopScreen7">
        <div className="HireDiv">
          <h2 className="HireDivh2">
            Hire the best developers and designers around!
          </h2>
          <img
            src="./Assets/Home/HireTopDevelopers.png"
            height={150}
            className="HireDivImage"
          />
        </div>
      </div>
      {/* ---------------Section Eight--------------- */}
      <div className="HomeTopScreen8">
        <h1>Our certified experts</h1>
        <p>
        Our certified experts provide tailored IT solutions for seamless digital transformation
        </p>

        <div className="expertMainDiv">
          {expert.map((item, index) => {
            return (
              <div className="expertDiv" key={index}>
                <img src={item.image} height={130} width={140} className="expertImage" />
                <p className="expertName">
                  {item.name} <br />
                  <text className="expertRole">{item.role}</text>
                </p>
                <div className="expertShareDiv">
                  <Link onClick={()=>handleInstagramClick(item.instagram)}  className="expertShare">
                    <img
                      src="./Assets/Footer/Instagram.png"
                      height={13}
                      alt="Facebook"
                    />
                  </Link>
                  <Link onClick={()=>handleTwitterClick(item.twitter)} className="expertShare">
                    <img
                      src="./Assets/Footer/Twitter.png"
                      height={10}
                      alt="Twitter"
                    />
                  </Link>
                  <Link onClick={()=>handleLinkedInClick(item.linkedin)} className="expertShare">
                    <img
                      src="./Assets/Footer/Linkdin.png"
                      height={10}
                      alt="LinkedIn"
                    />
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {/* ---------------Section Nine--------------- */}
      <div className="HomeTopScreen9">
        <div className="HomeTopScreen9Left">
          <div className="HomeTopScreen9Left1">
            <img
              src="./Assets/Home/Image1.png"
              height={200}
              className="HomeTopScreen9Image"
            />
            <img
              src="./Assets/Home/Image2.png"
              height={200}
              className="HomeTopScreen9Image1"
            />
          </div>
          <div className="HomeTopScreen9Left1">
            <img
              src="./Assets/Home/Image3.png"
              height={200}
              className="HomeTopScreen9Image"
            />
            <img
              src="./Assets/Home/Image4.png"
              height={200}
              className="HomeTopScreen9Image1"
            />
          </div>
        </div>
        <div className="HomeTopScreen9Right">
          <h5 className="HomeTopScreen9Righth5">PROJECTS</h5>
          <h1 className="HomeTopScreen9Righth1">
            Our amazing project
            <br /> that has been <br />
            completed
          </h1>
          <p className="HomeTopScreen9Rightpara">
            We are thrilled to announce the successful completion of our latest
            project – an endeavor that exemplifies innovation, dedication, and
            excellence. From its inception to the final delivery, our amazing
            project has showcased our team's commitment to pushing boundaries
            and achieving remarkable results.
          </p>
          <Link to="/Project" onClick={handleClick}>
            <button className="RecentProjects HomeTopScreen9Rightpara">
              Recent Projects
            </button>
          </Link>
          {/* <button to="/Project" className="RecentProjects">Recent Projects</button> */}
        </div>
      </div>
    </div>
  );
}

export default Home;
