import React from "react";
import "./CareerStyle.css";
import { Link } from "react-router-dom";

function Career() {
  const jobs = [
    {
      id: 1,
      jobTitle: "React JS",
      jobTitleColor: "rgba(0, 239, 209, 1), rgba(0, 187, 228, 1)",
      image: "./Assets/Career/ReactJS.png",
      JobName: "React JS Developer",
      skill:
        "JavaScript, React, SQL lite, Rest API, Docker, AWS and Git, Redux tool kit",
      exp: "(1.5 - 2 year) experience",
      Qualification: "Engineering Graduate",
      location: "Indore",
    },
    {
      id: 2,
      jobTitle: "React Native",
      jobTitleColor: "rgba(0, 239, 209, 1), rgba(0, 187, 228, 1)",
      image: "./Assets/Career/ReactNative.png",
      JobName: "React Native Developer",
      skill:
        " JavaScript, React, React Native, SQL lite, REST API, Docker, AWS and Git, redux, redux tool kit",
      exp: "(1.5 - 2 year) experience",
      Qualification: "Engineering Graduate",
      location: "Indore",
    },
    {
      id: 3,
      jobTitle: "Android",
      jobTitleColor: "rgba(78, 250, 137, 1), rgba(76, 175, 80, 1)",
      image: "./Assets/Career/Android.png",
      JobName: "Android Developer",
      skill:
        "Java/Kotlin, Android Studio, UI/UX Design, RESTful APIs, Version Control, AWS.",
      exp: "(1.5 - 2 year) experience",
      Qualification: "Engineering Graduate",
      location: "Indore",
    },
    {
      id: 4,
      jobTitle: "UI/UX",
      jobTitleColor: "rgba(247, 78, 250, 1), rgba(234, 103, 103, 1)",
      image: "./Assets/Career/UIUXDesigner.png",
      JobName: "UI/UX Designer",
      skill: "Sketch, Figma, Adobe XD View Requirement",
      exp: "(1.5 - 2 year) experience",
      Qualification: "Any Field",
      location: "Indore",
    },
  ];
  const handleClick = () => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
  };
  return (
    <div>
      {/* ---------------Section One--------------- */}
      <div className="CareerTopScreen">
        <div className="CareerTopScreenDiv1">
          <h1 className="CareerTopScreenh1 CareerTopScreenh11">Career</h1>
          <h2 className="CareerTopScreenh2">
            Welcome to GrowCraft Solution – Where Your Career Thrives!
          </h2>
          <h5 className="CareerTopScreenh1 CareerTopScreenh5">
            Join Our Innovative Team, Unlock Your Potential with us.
          </h5>
          <p className="CareerTopScreenh1 CareerTopScreenPara">
            Looking for an opportunity to showcase your web design skills? Join
            our team of talented designers, on-site, and bring your creativity
            to life. Collaborate with a dynamic group, create stunning websites,
            and make a lasting impact in the world of design, no matter where
            you are.
          </p>
        </div>
        <div className="CareerTopScreenDiv2">
          <div className="ScreentopImageDiv">
            <img
              src="./Assets/Career/CareerIcon.png"
              height={300}
              className="CareerIconImage"
            />
          </div>
        </div>
      </div>
      {/* ---------------Section Two--------------- */}
      <div className="CareerTopScreen2">
        <div className="ExploreLine"></div>
        <div className="JobsDiv">
          <h1>Jobs</h1>
          <h1 className="Openingh1">Openings</h1>
        </div>
        <div className="jobdetailsMainDiv">
          {jobs.map((item, index) => {
            return (
              <div key={index} className="jobdetailDiv">
                <div className="jobTitle">
                  <img src={item.image} height={40} className="jobTitleImage" />
                  <h3
                    style={{
                      background: `-webkit-linear-gradient(${item.jobTitleColor})`,
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                    }}
                    className="jobTitleh3"
                  >
                    {item.jobTitle}
                  </h3>
                </div>
                <h4 className="jobName">{item.JobName}</h4>
                <div className="jobTitle">
                  <p className="jobTitleText">Skill: </p>
                  <p className="jobTitlePara">{item.skill}</p>
                </div>
                <div className="jobTitle">
                  <p className="jobTitleText">Experience: </p>
                  <p className="jobTitlePara">{item.exp}</p>
                </div>
                <div className="jobTitle">
                  <p className="jobTitleText">Qualification: </p>
                  <p className="jobTitlePara">{item.Qualification}</p>
                </div>
                <div className="jobTitle">
                  <p className="jobTitleText">location: </p>
                  <p className="jobTitlePara">{item.location}</p>
                </div>
                <Link to="/Contact" onClick={handleClick}>
                  <button className="applybtn">Apply Now</button>
                </Link>
              </div>
            );
          })}
        </div>
      </div>
      {/* ---------------Section Three--------------- */}
      <div className="CareerTopScreen3">
        <div className="ExploreLine1"></div>
        <div className="JobsDiv1">
          <h1 className="Jobsh1">Why Work With</h1>
          <h1 className="Openingh1">GrowCraft Solution</h1>
        </div>
        <p className="careerbody">
          At GrowCraft Solution, we are not just an organization; we are a
          community of passionate individuals committed to fostering growth,
          innovation, and excellence. Choosing to work with us means becoming a
          part of a dynamic team that values creativity, collaboration, and
          continuous learning. Here's why you should consider joining our
          vibrant workplace
        </p>
        <img
          src="./Assets/Career/WhyWORKWITHGrowCraftSolution.png"
          className="WhyWORKWITHGrowCraftSolutionImage"
        />
      </div>
    </div>
  );
}

export default Career;
