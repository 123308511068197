import React from "react";
import "./FullStackStyle.css";
import { Link } from "react-router-dom";

function FullStack() {
  const data = [
    {
      id: 1,
      title: "End-to-End Web Development",
      body: "From conceptualization to deployment, we cover the entire web development lifecycle. Our full stack developers seamlessly integrate frontend and backend technologies to create robust and scalable web applications.",
    },
    {
      id: 2,
      title: "API Development and Integration",
      body: "Enable seamless communication between different software components with our API development and integration services. We ensure smooth interoperability and data exchange to enhance the functionality of your applications.",
    },
    {
      id: 3,
      title: "Frontend Development",
      body: "Craft visually stunning and user-centric interfaces with our frontend development expertise. We specialize in using the latest frameworks and technologies to deliver engaging and responsive user experiences.",
    },
    {
      id: 4,
      title: "Backend Development",
      body: "Power your applications with a robust and scalable backend. Our backend developers are proficient in building server-side logic, databases, APIs, and more, ensuring the seamless functionality of your applications.",
    },
    {
      id: 5,
      title: "Database Management",
      body: "Optimize data storage and retrieval with our database management services. We work with various database systems to ensure efficient data handling and management tailored to your project's needs.",
    },
    {
      id: 6,
      title: "DevOps and Deployment",
      body: "Streamline your development workflow with our DevOps practices. Our team ensures efficient collaboration, continuous integration, and automated deployment for faster and more reliable releases.",
    },
  ];
  const data2 = [
    {
      id: 1,
      title: "Versatility and Expertise:",
      body: "Our full stack developers are proficient in a wide range of technologies, ensuring versatility and expertise for every layer of your application.",
    },
    {
      id: 2,
      title: "Efficiency and Collaboration:",
      body: "We prioritize efficiency through streamlined development processes and collaborative teamwork, ensuring timely delivery and client satisfaction.",
    },
    {
      id: 3,
      title: "Scalability and Future-Proofing",
      body: "Our solutions are designed to scale with your business. We future-proof your applications, adapting them to evolving technologies and market trends.",
    },
  ];
  const handleClick = () => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
  };
  return (
    <div>
      {/* ---------------Section One--------------- */}
      <div
        className="FullStackTopScreen"
        style={{ backgroundImage: "url('./Assets/UXUI/BackgroundImage.png')" }}
      >
        <div className="FullStackTopScreenDiv1">
          <h1 className="FullStackh1">Full Stack Development services</h1>
          <h5 className="FullStackh5">
            Full-stack development refers to the practice of developing both the
            front-end (client-side) and back-end (server-side) parts of a web
            application.
          </h5>
          <p className="FullStackPara">
            At GrowCraft Solution, we pride ourselves on being your go-to
            destination for Full Stack Development services. Our skilled and
            dynamic team of developers is ready to take on the full spectrum of
            your development needs. Explore our comprehensive services and
            discover how we can bring your digital projects to life.
          </p>
          <Link to="/Contact" onClick={handleClick}>
            <button className="Letstalkbtn">Let's talk</button>
          </Link>
        </div>
        <div className="FullStackTopScreenDiv2">
          <img
            src="./Assets/FullStack/FullStackTopImage.png"
            height={350}
            className="FullStackTopImage"
          />
        </div>
      </div>
      {/* ---------------Section Two--------------- */}
      <div className="FullStackTopScreen2">
        <div className="CircleDot">
          <img
            src="./Assets/MobileApp/Dot1.png"
            height={60}
            className="Dot11"
          />
          <div className="CircleDivhi"></div>
        </div>
        <div className="FullStackTopScreen2Contact">
          <div className="Line11"></div>
          <div className="ECOMMERCEDiv">
            <h1 className="FullStackServicesH1">Our Full Stack</h1>
            <h1 className="DEVELOPMENTh1 FullStackServicesH1">
              Development Services
            </h1>
          </div>

          <div className="DEVELOPMENTMainDiv">
            {data.map((item, index) => {
              return (
                <div className="DEVELOPMENTDiv">
                  <div>
                    <h5 className="DEVELOPMENTh5">{item.title}</h5>
                    <p className="DEVELOPMENTPara">{item.body}</p>
                  </div>
                  <h1 className="DEVELOPMENTIndex">0{index + 1}</h1>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {/* ---------------Section Three--------------- */}
      <div className="FullStackTopScreen3">
        <div className="Line12 "></div>
        <h1 className="Servicesh12">Why Choose GrowCraft Solution for</h1>
        <h1 className="Servicesh1 Servicesh12">Full Stack Development</h1>
        <div className="FullStackDevelopmentMaindiv">
          <div>
            {data2.map((item, index) => {
              return (
                <div className="whychooseDiv" key={index}>
                  <div className="whychooseInnerDiv1">
                    <img src="./Assets/FullStack/Radiobtn.png" height={20} />
                  </div>
                  <div className="whychooseInnerDiv2">
                    <h6 className="whychooseh6">{item.title}</h6>
                    <p className="whychooseText">{item.body}</p>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="FullStackDevelopmentImageDiv">
            <img
              src="./Assets/FullStack/FullStackDevelopmentImage.png"
              height={320}
              className="FullStackDevelopmentImage"
            />
          </div>
        </div>
      </div>
      {/* ---------------Section Four--------------- */}
      <div className="FullStackTopScreen4">
        <div
          className="GreatTogetherDiv"
          style={{ backgroundImage: "url('./Assets/FullStack/BackImage.png')" }}
        >
          <h1 className="GreatTogetherDivh1">
            Let's Build Something Great Together!
          </h1>
          <p className="GreatTogetherDivh1">
            Ready to transform your digital ideas into reality? Contact us today
            to discuss your Full Stack Development needs, receive a customized
            proposal, and embark on a journey of innovation and success. At
            GrowCraft Solution, we don't just code; we architect digital
            experiences. Partner with us for comprehensive Full Stack
            Development solutions that empower your online presence.
          </p>
          <Link to="/Contact" onClick={handleClick}>
            <button className="ContactUsBtn">Contact Us</button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default FullStack;
