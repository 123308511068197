import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import swal from "sweetalert";

function Footer() {
  const handleClick = () => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
  };
  const handleSubmit = () => {
    swal(
      "Thanks for reaching out!",
      "We're excited to talk to you and will reply as soon as we can",
      "success"
    );
  };
  const handleLinkedInClick = () => {
    window.open("https://www.linkedin.com/company/growcraft-solution-pvt-ltd/", "_blank");
  };

  const handleInstagramClick = () =>{
    window.open("https://www.instagram.com/growcraftsolution/", "_blank");
  }
  const handleTwitterClick = () =>{
    window.open("https://twitter.com/GrowcraftS", "_blank");
  }
  return (
    <div className="footerMain">
      <div className="footerTop">
        <div style={{ display: "flex" }}>
          <div className="footerTopContent">
            <img src="./Assets/Footer/Call.png" className="footerTopImg" alt="footerTopImg"/>
          </div>{" "}
          <div>
            <p className="footerTopText">
              (+91) 9516327187
              <p className="footerTopText1">Hotline 24/7</p>
            </p>
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <div className="footerTopContent">
            <img src="./Assets/Footer/Mail.png" className="footerTopImg" />
          </div>
          <div>
            <p className="footerTopText">
              Info@growcraftsolution.com
              <p className="footerTopText1">Free consult</p>
            </p>
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <div className="footerTopContent">
            <img src="./Assets/Footer/location1.png" className="footerTopImg" />
          </div>{" "}
          <div className="footerTopTextAddress">
            <p className="footerTopText">
              5th floor, Office No. 508, NRK Biz Park, Sch. No. 54, Vijay Nagar
              Indore, Madhya Pradesh (452010)
            </p>
          </div>
        </div>
      </div>
      <hr className="hrtag" />
      <div className="footerBody">
        <div>
          <div>
            <img src="./Assets/Footer/Logo.png" className="footerBodyLogo" />
            <p className="footerbodyText">
              Our mission is to seamlessly integrate technology into your
              operations, ensuring it not only works alongside you but works for
              your benefit.
            </p>
          </div>
          <div className="FooterBodyIconDiv">
            <Link className="FooterBodyIcon" onClick={handleInstagramClick}>
              <img
                src="./Assets/Footer/Instagram.png"
                height={12}
                alt="Facebook"
              />
            </Link>
            <Link onClick={handleTwitterClick} className="FooterBodyIcon">
              <img
                src="./Assets/Footer/Twitter.png"
                height={10}
                alt="Twitter"
              />
            </Link>
            <Link className="FooterBodyIcon"  onClick={handleLinkedInClick}>
              <img
                src="./Assets/Footer/Linkdin.png"
                height={10}
                alt="LinkedIn"
              />
            </Link>
            <div className="FooterBodyIcon">
              <img
                src="./Assets/Footer/WhatsApp.png"
                height={10}
                alt="WhatsApp"
              />
            </div>
          </div>
        </div>
        <div></div>
        <div>
          <p className="ServicesText">Our Services</p>
          <div className="footerBodytitle">
            <div className="footerBodytitle">
              <Link
                to="/MobileApp"
                className="footerBodytitle1"
                onClick={handleClick}
              >
                <img src="./Assets/Footer/arrow.png" className="arrowSign" />
                Mobile Development
              </Link>
            </div>
            <div className="footerBodytitle">
              <Link
                to="/UXUI"
                className="footerBodytitle1"
                onClick={handleClick}
              >
                <img src="./Assets/Footer/arrow.png" className="arrowSign" />
                UX Development
              </Link>
            </div>
            <div className="footerBodytitle">
              <Link
                to="/WebDevelopment"
                className="footerBodytitle1"
                onClick={handleClick}
              >
                <img src="./Assets/Footer/arrow.png" className="arrowSign" />
                Web Development
              </Link>
            </div>
            <div className="footerBodytitle">
              <Link
                to="/FullStack"
                className="footerBodytitle1"
                onClick={handleClick}
              >
                <img src="./Assets/Footer/arrow.png" className="arrowSign" />
                Full Stack Development
              </Link>
            </div>
          </div>
        </div>
        <div></div>
        <div className="CompanyDiv">
          <p className="ServicesText">Company</p>
          <div className="footerBodytitle">
            <div>
              <Link className="footerBodytitle1" to="/" onClick={handleClick}>
                <img src="./Assets/Footer/arrow.png" className="arrowSign" />
                Home
              </Link>
            </div>
            <div className="footerBodytitle">
              <Link
                className="footerBodytitle1"
                to="/AboutUs"
                onClick={handleClick}
              >
                <img src="./Assets/Footer/arrow.png" className="arrowSign" />
                About Us
              </Link>
            </div>
            <div className="footerBodytitle">
              <Link
                className="footerBodytitle1"
                to="/Career"
                onClick={handleClick}
              >
                <img src="./Assets/Footer/arrow.png" className="arrowSign" />
                Career
              </Link>
            </div>
            <div className="footerBodytitle">
              <Link
                className="footerBodytitle1"
                to="/Contact"
                onClick={handleClick}
              >
                <img src="./Assets/Footer/arrow.png" className="arrowSign" />
                Contact Us
              </Link>
            </div>
          </div>
        </div>
        <div>
          <p className="ServicesText">NEWSLETTER</p>
          <div className="footerBodytitle">
            <form
              className="footerInputBody"
              action="https://formsubmit.co/00a5303ff33bde7076c9f1af62ce1c95"
              method="POST"
              onSubmit={handleSubmit}
            >
              {/* Honeypot */}
              <input type="text" name="_honey" style={{ display: "none" }} />

              {/* Disable Captch */}
              <input type="hidden" name="_captcha" value="false" />

              <input
                type="hidden"
                name="_next"
                value="https://growcraftsolution.com/Contact"
              />
              <input
                type="email"
                className="footerInput"
                placeholder="Enter your email"
                name="Email"
                required
              />
              <button type="submit" className="rightClick">
                <img
                  src="./Assets/Footer/rightClick.png"
                  className="footerRightClick"
                />
              </button>
            </form>
            <p>
              A newsletter is a concise and informative publication distributed
              regularly to a specific audience,
            </p>
          </div>
        </div>
      </div>
      <div className="footerbuttom">
        <p className="footerbuttomText">
          Copyright <i class="fa-regular fa-copyright"></i> 2023 GrowCraft
          Solution Pvt. Ltd. All Right Reserved
        </p>
      </div>
    </div>
  );
}

export default Footer;
