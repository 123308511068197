import React, { useState } from "react";
import "./AboutUsStyle.css";
import { Link } from "react-router-dom";

const AboutUs = () => {
  const data = [
    {
      id: 1,
      image: "./Assets/CompanyEmployeePic/ArvindAhirwar.png",
      name: "Arvind Ahirwar",
      role: "Founder / CEO",
      experience: "Over 10+ years experience",
      body: "At GrowCraft Solutions, we are deeply committed to our clients' success. Our mission is to create impactful products and services that not only meet our clients' needs but also exceed their expectations. We believe that true success stems from building a sustainable, talented, and cohesive team.Led by Mr. Arvind Ahirwar, our CEO, we prioritize technical excellence and innovation. Mr. Ahirwar is dedicated to fostering a culture of continuous learning and growth within our organization. His expertise in leading the development of cutting-edge products and technologies drives our company forward.At GrowCraft Solutions, we embrace challenges as opportunities for growth. We measure our success by the success of our clients and their customers. ",
      linkedin: "https://www.linkedin.com/in/arvind-choudhary-87194627b/",
      skype: "https://join.skype.com/invite/taPVro8WhW3J",
      twitter: "https://twitter.com/ArvindA78168040",
    },
    {
      id: 2,
      image: "./Assets/CompanyEmployeePic/DharmendraAhirwar.jpeg",
      name: "Dharmendra Ahirwar",
      role: "CTO",
      experience: "Over 5+ years experience",
      body: " We work with passion. We are enthusiastic about the work we do and the products we build.I am proud of our company and the people that have brought us to where we are today, and I am excited about where we are going tomorrow. As CTO of GrowCraft Mr Dharmendra Ahirwar is the visionary of the company when it comes to the technical aspect of Growcrafts processes. He set new goals and challenges in the coming years, GrowCraft will remain to be committed to the core values of quality, customer delight, integrity, agility and efficiency - the same core values that have brought GrowCraft to where we are today, a world class IT service and solutions company offering IT service and solutions to key markets in Asia, Americas and Australia.",
      linkedin: "https://www.linkedin.com/in/dharmendra-choudhary20/",
      skype: "https://join.skype.com/invite/ps3vueFpe3cE",
      twitter: "https://twitter.com/Dharmendra20024",
    },
  ];
  const employees = [
    {
      id: 1,
      name: "Deepchand Ahriwar",
      role: "Full Stack Developer",
      photo: "./Assets/CompanyEmployeePic/DeepchandAhirwar.jpeg",
    },
    {
      id: 2,
      name: "Sanjay Khangora",
      role: "As. Software Developer",
      photo: "./Assets/CompanyEmployeePic/SanjayKhangora.jpg",
    },
    {
      id: 3,
      name: "Suraj Kushwaha",
      role: "React Developer",
      photo: "./Assets/CompanyEmployeePic/SurajKushwaha.jpeg",
    },
    {
      id: 4,
      name: "Jayshree Solanki",
      role: "Sr. React Native Developer",
      photo: "./Assets/CompanyEmployeePic/JayshreeSolanki.jpg",
    },
    {
      id: 5,
      name: "Kimi Vyas",
      role: "Sr. Android Developer",
      photo: "./Assets/CompanyEmployeePic/KimiVyas.jpg",
    },
    {
      id: 6,
      name: "Rehan Mirza",
      role: "Android Developer",
      photo: "./Assets/CompanyEmployeePic/RehanMirza1.jpeg",
    },
    {
      id: 8,
      name: "Akash Chouby",
      role: "Android Developer",
      photo: "./Assets/CompanyEmployeePic/AkashChoubey.jpg",
    },
    {
      id: 9,
      name: "Sonu Ahirwar",
      role: "UI / UX Designer",
      photo: "./Assets/CompanyEmployeePic/SonuAhirwar.jpg",
    },
    {
      id: 10,
      name: "Vijay Hencha",
      role: "UI / UX Designer",
      photo: "./Assets/CompanyEmployeePic/VijayHench.jpg",
    },
    {
      id: 11,
      name: "Jitendra Sisodiya",
      role: "UI / UX Designer",
      photo: "./Assets/CompanyEmployeePic/JitendraSisodiya.jpg",
    },
    {
      id: 12,
      name: "Deeksha Manjhi",
      role: "Sr. UI / UX Designer",
      photo: "./Assets/CompanyEmployeePic/DeekshaManjhi.jpg",
    },
    {
      id: 13,
      name: "Amar Ahirwar",
      role: "UI / UX Designer",
      photo: "./Assets/CompanyEmployeePic/AmarAhirwar.jpeg",
    },
    {
      id: 14,
      name: "Shradha Ahirwar",
      role: "Content Writer",
      photo: "./Assets/CompanyEmployeePic/shraddhaAhirwar.jpg",
    },
  ];
  const [currentSlide, setCurrentSlide] = useState(0);

  const prevSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? data.length - 1 : prevSlide - 1
    );
  };

  const nextSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === data.length - 1 ? 0 : prevSlide + 1
    );
  };

    const handleLinkedInClick = (linkedin) => {
      window.open(linkedin, "_blank");
    };

    const handleTwitterClick = (Twitter) => {
      window.open(Twitter, "_blank");
    };

    const handleSkypeClick = (Skype) => {
      window.open(Skype, "_blank");
    };
  return (
    <div>
      {/* ---------------Section One--------------- */}
      <div className="AboutTopScreen">
        <div
          style={{ backgroundImage: "url('./Assets/Aboutus/LineDesign.png')" }}
          className="LineDesignDiv"
        ></div>
        <div className="CraftingDesign">
          <h1 className="gradient-text">"CRAFTING DIGITAL SOLUTIONS"</h1>
          <h1 className="growcraft-text">
            GROWCRAFT IS A REPUTABLE IT SOFTWARE COMPANY
          </h1>
          <hr className="growcraft-hr" />
          <p className="growcraft-para">
            GrowCraft Solutions is dedicated to pushing the boundaries of
            innovation
          </p>
        </div>
      </div>
      {/* ---------------Section Two--------------- */}
      <div className="AboutTopScreen2">
        <div className="AboutTopScreenDiv">
          <img
            src="./Assets/Aboutus/WHOWEARE.png"
            height={300}
            className="AboutTopScreenDivImage"
          />
        </div>
        <div className="AboutTopScreenDiv1">
          <div className="WHOWEAREMainDiv">
            <div className="WHOWEAREDiv"></div>
            <text className="WHOWEAREText">WHO WE ARE</text>
          </div>
          <h2 className="WHOWEAREh2">
            We combine our experience and passion to develop solutions that
            empower businesses.
          </h2>
          <h4 className="WHOWEAREh4">
            GrowCraft is a Software Development Company
          </h4>
          <p className="WHOWEAREpara">
            GrowCraft Solutions Pvt. Ltd., established in 2023, is a dynamic and
            forward-thinking software development company. Despite being a
            relatively young player in the industry, our commitment to
            excellence and innovation has propelled us to remarkable heights.
          </p>
          <p className="WHOWEAREpara">
            With a dedicated team of 20+ skilled professionals, comprising
            developers, designers, quality assurance engineers, and project
            managers, we take pride in our ability to deliver cutting-edge
            solutions. Our focus on staying abreast of the latest industry
            technologies and trends allows us to provide world-class mobile and
            web development services.
          </p>
        </div>
      </div>
      {/* ---------------Section Three--------------- */}
      <div className="AboutTopScreen3">
        <div className="OurMissionDiv1">
          <div className="OurMissionHeading">
            <h2 className="OurText">Our</h2>
            <h2 className="OurText1"> Mission</h2>
          </div>
          <p className="OurMissionBody">
            Provide cost-effective, high-quality, innovative solutions &
            services, powered by state-of-the-art technologies, anchored on our
            basic principles:
          </p>
          <p className="OurMissionBody1">
            The strong R&D team at GrowCraft Solution is consistently working to
            upgrade existing solutions and develop new products.GrowCraft
            Solution is transparent and accountable to customers, shareholders,
            partners, and employees, striving to deliver on commitments and
            produce tangible results.
          </p>
          <div className="SimformDiv">
            <p className="SimformText">
              "Simform is quick to identify larger problem with the Software so
              we decided to expand our scope to build new modules"
            </p>
          </div>
          <div className="founderdiv">
            <img
              src="./Assets/CompanyEmployeePic/Expert/ArvindAhirwar.png"
              height={50}
              width={50}
              className="founderImage"
            />
            <div>
              <h5 className="founderText1">Arvind Ahirwar</h5>
              <text className="founderText">Founder</text>
            </div>
          </div>
        </div>
        <div className="OurMissionDiv2">
          <img
            src="./Assets/Aboutus/OurMission.png"
            height={350}
            className="OurMissionImage"
          />
        </div>
      </div>
      {/* ---------------Section Four--------------- */}
      <div className="AboutTopScreen4">
        <div className="OurValueDiv2">
          <img
            src="./Assets/Aboutus/OurValues.png"
            height={350}
            className="OurMissionImage OurMissionImage1"
          />
        </div>
        <div className="OurMissionDiv1">
          <div className="OurMissionHeading">
            <h2 className="OurText">Our</h2>
            <h2 className="OurText1">Values</h2>
          </div>
          <p className="OurMissionBody">
            Provide cost-effective, high-quality, innovative solutions &
            services, powered by state-of-the-art technologies, anchored on our
            basic principles:
          </p>
          <ul className="OurMissionBody1">
            <li className="Ensuringtotalcustomer">
              Ensuring total customer satisfaction, continuous improvement, and
              total involvement.
            </li>
            <li className="Ensuringtotalcustomer">
              Constantly and consistently delivering products and services of
              the highest quality.
            </li>
            <li className="Ensuringtotalcustomer">
              Keeping pace with change and continuously striving for innovation
              while embracing modern technology and methodologies.
            </li>
          </ul>
          <div className="SimformDiv">
            <p className="SimformText">
              "Simform is quick to identify larger problem with the Software so
              we decided to expand our scope to build new modules"
            </p>
          </div>
          <div className="founderdiv">
            <img
              src="./Assets/CompanyEmployeePic/Expert/ArvindAhirwar.png"
              height={50}
              width={50}
              className="founderImage"
            />
            <div>
              <h5 className="founderText1">Arvind Ahirwar</h5>
              <text className="founderText">Founder</text>
            </div>
          </div>
        </div>
      </div>
      {/* ---------------Section Five--------------- */}
      <div className="AboutTopScreen5">
        {data.map((item, index) => (
          <div
            className="slide"
            key={index}
            style={{ display: index === currentSlide ? "block" : "none" }}
          >
            <div className="datamap">
              <div className="FounderCEODiv">
                <div className="FounderCEOLine"></div>
                <h5 className="founderRole">{item.role}</h5>
                <h3 className="founderName">{item.name}</h3>
                <p className="founderBody">{item.body}</p>
                <hr className="founderhr" />
                <h3 className="founderh3">Experience</h3>
                <p className="founderExp">{item.experience}</p>
                <div className="shareIconMainDiv">
                  <Link onClick={()=>handleLinkedInClick(item.linkedin)} className="shareIconDiv">
                    <img
                      src="./Assets/Footer/Linkdin.png"
                      height={20}
                      className="shareIconDivImage"
                    />
                  </Link>
                  <Link onClick={()=>handleTwitterClick(item.twitter)} className="shareIconDiv">
                    <img
                      src="./Assets/Footer/Twitter.png"
                      height={20}
                      className="shareIconDivImage"
                    />
                  </Link>
                  <Link onClick={()=>handleSkypeClick(item.skype)} className="shareIconDiv">
                    <img
                      src="./Assets/Footer/Skype.png"
                      height={20}
                      className="shareIconDivImage"
                    />
                  </Link>
                </div>
              </div>
              <div className="managerdiv">
                <img
                  src={item.image}
                  height={380}
                  width={320}
                  className="managerdivImage"
                  alt="Slide"
                />
              </div>
            </div>
          </div>
        ))}
        <div className="iconContainer">
          {data.map((_, index) => (
            <span
              key={index}
              className={`icon ${index === currentSlide ? "active" : ""}`}
              onClick={() => setCurrentSlide(index)}
            />
          ))}
        </div>
        <button className="prev1" onClick={prevSlide}>
          &#10094;
        </button>
        <button className="next1" onClick={nextSlide}>
          &#10095;
        </button>
      </div>
      {/* ---------------Section Six--------------- */}
      <div className="AboutTopScreen6">
        <div className="linediv"></div>
        <h6 className="ourteam">Our Team</h6>
        <h1 className="ourteamh1">Colaboring and optimizing for growth</h1>
        <p className="ourteamPara">
          The team at petrong we are powering the growth of technology ecosystem
        </p>
        <div className="employeesmapdiv">
          {employees.map((item, index) => {
            return (
              <div key={index} className="employeedetails">
                <img
                  src={item.photo}
                  height={80}
                  style={{ margin: 15 }}
                  className="employeedetailsImage"
                />
                <h6 className="employeedetailsh6">{item.name}</h6>
                <p className="employeedetailsPara">{item.role}</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
