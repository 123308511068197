import React from "react";
import "./EcommerceStyle.css";
import { Link } from "react-router-dom";

function Ecommerce() {
  const data = [
    {
      id: 1,
      image: "./Assets/Ecommerce/CustomizedSolutions.png",
      title: "Customized Solutions",
      body: "We don't believe in one-size-fits-all. Our experts collaborate with you to understand your unique business needs and deliver tailor-made e-commerce solutions that resonate with your brand identity.",
    },
    {
      id: 2,
      image: "./Assets/Ecommerce/Scalability.png",
      title: "Scalability",
      body: "As your business grows, so should your online presence. Our scalable e-commerce solutions ensure that your platform evolves seamlessly to accommodate increasing demands and expanding product catalogs.",
    },
    {
      id: 3,
      image: "./Assets/Ecommerce/UserCentricDesign.png",
      title: "User-Centric Design",
      body: "We prioritize the user experience. Our team designs intuitive interfaces and ensures smooth navigation, encouraging visitors to explore and convert, ultimately enhancing your conversion rates.",
    },
    {
      id: 4,
      image: "./Assets/Ecommerce/MobileOptimization.png",
      title: "Mobile Optimization",
      body: "  Protecting your business and customer data is non-negotiable. Our e-commerce development includes robust security measures to provide a safe and secure shopping environment for your customers.",
    },
    {
      id: 5,
      image: "./Assets/Ecommerce/Security.png",
      title: "Security",
      body: " Protecting your business and customer data is non-negotiable. Our e-commerce development includes robust security measures to provide a safe and secure shopping environment for your customers.",
    },
    {
      id: 6,
      image: "./Assets/Ecommerce/IntegrationCapabilities.png",
      title: "Integration Capabilitiess",
      body: "Enhance efficiency by integrating third-party tools, payment gateways, and other essential components seamlessly into your e-commerce platform. Streamline operations and offer a hassle-free experience to your customers.",
    },
  ];
  const handleClick = () => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
  };
  return (
    <div>
      {/* ---------------Section One--------------- */}
      <div
        className="EcommerceTopScreen"
        style={{ backgroundImage: "url('./Assets/UXUI/BackgroundImage.png')" }}
      >
        <div className="EcommerceTopScreenDiv1">
          <h1 className="Ecommerceh1">E-commerce Development services</h1>
          <h5 className="Ecommerceh5">
            Empower Your Business with Cutting-Edge E-commerce Solutions:
            Unleash Seamless Online Shopping Experiences with Our Expert
            E-commerce Development Services.
          </h5>
          <p className="EcommercePara">
            Welcome to GrowCraft Solution, where innovation meets e-commerce
            excellence. Our dedicated team of seasoned developers is committed
            to transforming your online business aspirations into a powerful
            reality. Here, we understand that a robust and user-friendly
            e-commerce platform is the backbone of a successful online venture.
            With our comprehensive e-commerce development services, we craft
            tailored solutions that not only meet but exceed your business
            requirements.
          </p>
          <Link to="/Contact" onClick={handleClick}>
            <button className="Letstalkbtn">Let's talk</button>
          </Link>
        </div>
        <div className="EcommerceTopScreenDiv2">
          <img
            src="./Assets/Ecommerce/EcommerceTopImage.png"
            height={350}
            className="EcommerceTopImage"
          />
        </div>
      </div>
      {/* ---------------Section Two--------------- */}
      <div className="EcommerceTopScreen2">
        <div className="CircleDot">
          <img
            src="./Assets/MobileApp/Dot1.png"
            height={60}
            className="DotDot"
          />
          <div className="CircleDivDiv"></div>
        </div>
        <div className="EcommerceTopScreen2Contact">
          <div className="LineDiv"></div>
          <div className="ECOMMERCEWEBSITEDiv1">
            <h1 className="ECOMMERCEh1">ECOMMERCE WEBSITE</h1>
            <h1 className="ECOMMERCEWEBSITEh1  ECOMMERCEh1">
              DEVELOPMENT COMPANY
            </h1>
          </div>
          <ul>
            <li className="ECOMMERCEWEBSITEul">
              Unlock the full potential of your online business with GrowCraft
              Solution, a leading E-commerce Website Development Company.
              Specializing in crafting tailored solutions, we combine innovation
              with expertise to deliver seamless, secure, and scalable
              e-commerce platforms. From user-centric designs to robust
              integrations and ongoing support, we are your trusted partner in
              turning digital ambitions into a thriving reality. Elevate your
              online presence with GrowCraft Solution – where E-commerce meets
              excellence.
            </li>
            <li className="ECOMMERCEWEBSITEul">
              The website is the first impression your business will have on
              potential customers. However, as online shopping becomes
              increasingly popular, e-commerce has become a go-to platform for
              expanding the client base and providing a great online purchasing
              experience while ensuring that client's requirements are met in
              the greatest feasible way.
            </li>
            <li className="ECOMMERCEWEBSITEul">
              It's not just about selling with e-commerce; it's also about
              providing an excellent customer experience. And this is where
              GrowCraft Solution comprehensive e-commerce Website Development
              Services come into play. So, if you're selling or want to sell
              anything on the internet, GrowCraft Solution is the place to go
              for beautiful e-commerce Web Development.
            </li>
            <li className="ECOMMERCEWEBSITEul">
              The e-Commerce Website Development Industry is flooded with many
              businesses claiming to deliver e-commerce integration, so find and
              select the appropriate company is critical. BestPeers provides a
              variety of e-Commerce Website Development Solutions, and we will
              assist you in choosing the best one for your business based on
              your budget.
            </li>
          </ul>
          <div className="CircleDot ">
            <div></div>
            <div className="CircleDivDiv"></div>
          </div>
          <h3 className="WhyChooseOurEcommerceh3">
            Why Choose Our E-commerce Development Services?
          </h3>
          <div className="WhyChooseOurEcommerceMainDiv">
            {data.map((item, index) => {
              return (
                <div key={index} className="WhyChooseOurEcommerceDiv">
                  <div>
                    <img
                      src={item.image}
                      className="WhyChooseOurEcommerceImage"
                    />
                    <h4 className="WhyChooseOurEcommerceh4">{item.title}</h4>
                    <p>{item.body}</p>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="ReadyMainDiv">
            <div className="ReadyDiv1">
              <h3 className="Readyh3">
                Ready to take your online business to new heights? Let's embark
                on a journey of digital success together. Your Trusted Partner
                in E-commerce Development.
              </h3>
              <Link to="/Contact" onClick={handleClick}>
                <button className="ContactBtn">Contact Us</button>
              </Link>
            </div>
            <div>
              <img
                src="./Assets/Ecommerce/ReadyImage.png"
                height={200}
                className="ReadyImage1"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Ecommerce;
