import React from "react";
import "./MobileAppStyle.css";
import { Link } from "react-router-dom";

function MobileApp() {
  const data = [
    {
      id: 1,
      title: "Native App Development",
      body: "Harness the full potential of iOS and Android platforms with our native app development services. We create seamless and high-performance applications that leverage the unique features of each operating system.",
      backgroundColor: "rgba(254, 197, 252, 0.38)",
      textColor: "rgba(255, 54, 248, 1)",
    },
    {
      id: 2,
      title: "Cross-Platform App Development",
      body: "Reach a wider audience and reduce development costs with our cross-platform app development expertise. We use leading frameworks to deliver apps that function seamlessly across multiple platforms.",
      backgroundColor: "rgba(254, 197, 252, 0.38)",
      textColor: "rgba(255, 54, 248, 1)",
    },
    {
      id: 3,
      title: "Mobile Web App Development",
      body: "Responsive and user-friendly, our mobile web app development services ensure a consistent experience across various devices, enhancing accessibility and engagement",
      backgroundColor: "rgba(180, 241, 255, 0.38)",
      textColor: "rgba(45, 188, 221, 1)",
    },
    {
      id: 4,
      title: "UI/UX Design",
      body: "Elevate user experience with our expert UI/UX design services. Our designers blend creativity with functionality to create visually stunning and intuitive interfaces.",
      backgroundColor: "rgba(180, 241, 255, 0.38)",
      textColor: "rgba(45, 188, 221, 1)",
    },
    {
      id: 5,
      title: "App Maintenance and Support",
      body: "Ensure the ongoing success of your app with our reliable maintenance and support services. We proactively address issues, implement updates, and provide timely assistance to keep your app running smoothly.",
      backgroundColor: "rgba(182, 255, 189, 0.38)",
      textColor: "rgba(33, 222, 51, 1)",
    },
    {
      id: 6,
      title: "App Testing and Quality Assurance",
      body: "Delivering flawless apps is our commitment. Our rigorous testing and quality assurance processes guarantee a bug-free and optimal performance for your application.",
      backgroundColor: "rgba(182, 255, 189, 0.38)",
      textColor: "rgba(33, 222, 51, 1)",
    },
  ];
  const data2 = [
    {
      id: 1,
      title: "Proven Track Record:",
      body: "Explore our portfolio showcasing successful projects across diverse industries.",
    },
    {
      id: 2,
      title: "Client-Centric Approach:",
      body: "We believe in collaboration and transparency, involving clients in every stage of the development process.",
    },
    {
      id: 3,
      title: "Security First:",
      body: "Your data's security is our priority. We implement robust security measures to safeguard your app and user information.",
    },
    {
      id: 4,
      title: "Scalability and Flexibility:",
      body: "Whether you're a startup or an enterprise, our services are designed to scale according to your project's needs.",
    },
  ];
  const handleClick = () => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
  };
  return (
    <div>
      {/* ---------------Section One--------------- */}
      <div
        className="MobileAppTopScreen"
        style={{ backgroundImage: "url('./Assets/UXUI/BackgroundImage.png')" }}
      >
        <div className="MobileAppTopScreenDiv1">
          <h1 className="MobileApph1">Mobile App Development services</h1>
          <h5 className="MobileApph5">
            Welcome to GrowCraft Solution - Your Trusted Partner in Mobile
            Application Development
          </h5>
          <p className="MobileAppPara">
            At GrowCraft Solution, we specialize in delivering cutting-edge and
            tailored mobile application development solutions. Our team of
            experienced developers, designers, and strategists is committed to
            bringing your ideas to life and ensuring the success of your mobile
            app projects. Discover how our comprehensive range of services can
            elevate your digital presence and drive business growth.
          </p>
          <Link to="/Contact" onClick={handleClick}>
            <button className="Letstalkbtn">Let's talk</button>
          </Link>
        </div>
        <div className="MobileAppTopScreenDiv2">
          <img
            src="./Assets/MobileApp/MobileAppTopImage.png"
            height={350}
            className="MobileAppTopImage"
          />
        </div>
      </div>
      {/* ---------------Section Two--------------- */}
      <div className="MobileAppTopScreen2">
        <div className="CircleDot">
          <img
            src="./Assets/MobileApp/Dot1.png"
            height={60}
            className="Dot11"
          />
          <div className="CircleDiv CircleDiv1"></div>
        </div>
        <div className="MobileAppTopScreen2Contact">
          <div className="Line5"></div>
          <h1 className="Servicesh1Text">Our Mobile Application Development</h1>
          <h1 className="Servicesh1 Servicesh1Text">Services</h1>
          <div className="ServicesMainDiv">
            {data.map((item, index) => {
              return (
                <div className="ServicesDiv">
                  <div
                    style={{
                      backgroundColor: item.backgroundColor,
                      color: item.textColor,
                    }}
                    className="NumberDiv"
                  >
                    {index + 1}
                  </div>
                  <div>
                    <h5 className="Servicestext">{item.title}</h5>
                    <p className="ServicesPara">{item.body}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {/* ---------------Section Three--------------- */}
      <div className="MobileAppTopScreen3">
        <div className="CircleDot">
          <div className="CircleDiv CircleDiv1"></div>
          <img
            src="./Assets/MobileApp/Dot2.png"
            height={60}
            className="Dot11"
          />
        </div>
        <div className="MobileAppTopScreen2Contact">
          <div className="Line6"></div>
          <h1 className="Servicesh1Text">Why Choose GrowCraft Solution for</h1>
          <h1 className="Servicesh1 Servicesh1Text">Mobile App Development</h1>
          <div className="MobileAppDevelopmentMaindiv">
            <div>
              {data2.map((item, index) => {
                return (
                  <div className="whychooseDiv" key={index}>
                    <div className="whychooseInnerDiv1">
                      <img src="./Assets/MobileApp/Radiobtn.png" height={20} />
                    </div>
                    <div className="whychooseInnerDiv2">
                      <h6 className="whychooseh6">{item.title}</h6>
                      <p className="whychooseText">{item.body}</p>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="MobileAppDevelopmentImageDiv">
              <img
                src="./Assets/MobileApp/MobileAppDevelopmentImage.png"
                className="MobileAppDevelopmentImage"
                height={350}
              />
            </div>
          </div>
        </div>
      </div>
      {/* ---------------Section Four--------------- */}
      <div className="MobileAppTopScreen4">
        <h1 className="WebDesignDivh11">Platforms We Target</h1>
        <p className="whychooseText whychooseText1">
          ScienceSoft experts create UI/UX designs that are both user-friendly
          and trendy, be it a native or cross-platform app. We are skilled in
          implementing sleek designs for{" "}
          <text className="iOSUnderLine">iOS</text> and{" "}
          <text className="iOSUnderLine">Android</text> apps, as well as for
          applications on React Native, Flutter, and{" "}
          <text className="iOSUnderLine">Xamarin</text>.
        </p>
        <h1 className="WebDesignDivh11">App Development</h1>
        <p className="whychooseText whychooseText1">
          At the core of our mobile competencies, UI/UX design goes along with{" "}
          <text className="iOSUnderLine">mobile app development</text>. This
          important part of our services relies on efficient cross-team
          communication and time-proved methodologies that ease the entire
          process from developing a prototype to releasing the app.
        </p>
        <div className="WebDesignDiv12">
          <h1 className="WebDesignDivh1 WebDesignDivh11">Get Started Today!</h1>
          <p className="WebDesignDivh1 whychooseText1">
            Ready to turn your mobile app vision into reality? Contact us now to
            discuss your project requirements, receive a personalized quote, and
            take the first step toward a successful app development journey. At
            GrowCraft Solution, we don't just build apps; we craft digital
            experiences that make a lasting impact. Partner with us for
            innovative solutions and unparalleled expertise in mobile
            application development.
          </p>
          <Link to="/Contact" onClick={handleClick}>
            <button className="ContactUsBtn">Contact Us</button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default MobileApp;
