import logo from './logo.svg';
import './App.css';
import Root from './Navigation/Root';

function App() {
  return (
    <Root />
  );
}

export default App;
