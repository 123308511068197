import React from "react";
import "./WebDevelopmentStyle.css";
import { Link } from "react-router-dom";

function WebDevelopment() {
  const data = [
    {
      id: 1,
      title: "Web Design",
      body: "Web design is a critical aspect of creating a visually appealing and user-friendly online presence. It involves the planning, conceptualization, and arrangement of visual elements on a website to enhance its aesthetics and usability. Harness the power of exceptional design to leave a lasting impression and drive meaningful user interactions on your website.",
      backColor: "rgba(208, 131, 244, 0.38)",
      textColor: "rgba(207, 13, 255, 1)",
    },
    {
      id: 2,
      title: "Web Apps",
      body: "Revolutionize user experiences with our dynamic web apps. Tailored for seamless functionality, our applications offer responsive interfaces, real-time updates, and cross-platform compatibility. Elevate engagement with interactive features and boost productivity. Experience the future of web-based solutions with our innovative and user-centric web applications.",
      backColor: "rgba(114, 255, 170, 0.38)",
      textColor: "rgba(0, 225, 144, 1)",
    },
    {
      id: 3,
      title: "E- Commerce",
      body: "We design and develop secure, user-friendly online stores that maximize sales and customer satisfaction. Seamlessly integrate payment gateways, manage inventory effortlessly, and provide a frictionless shopping experience. Elevate your brand's online retail presence and boost revenue with our tailored E-commerce services.",
      backColor: "rgba(194, 237, 255, 1)",
      textColor: "rgba(11, 197, 255, 1)",
    },
  ];
  const data1 = [
    {
      id: 1,
      image: "./Assets/WebDevelopment/RequirementAnalysis.png",
      title: "Requirement Analysis",
      body: "Requirement analysis is a crucial phase in the software development lifecycle, aiming to comprehend and document system needs. It involves gathering, documenting, and validating user requirements to ensure that the final product meets stakeholder expectations.",
    },
    {
      id: 2,
      image: "./Assets/WebDevelopment/UIUXDesign.png",
      title: "UI/UX Design",
      body: "UI/UX design is pivotal in creating user-friendly digital experiences. UI (User Interface) focuses on the visual elements, ensuring an attractive and intuitive design. Meanwhile, UX (User Experience) emphasizes overall user satisfaction by enhancing accessibility, usability, and functionality. Combining these disciplines optimizes.",
    },
    {
      id: 3,
      image: "./Assets/WebDevelopment/FrontendDevelopment.png",
      title: "Front-end Development",
      body: "Front-end development involves crafting the visual and interactive aspects of websites or applications that users directly interact with. Developers use HTML, CSS, and JavaScript to create responsive, engaging interfaces. It's crucial for ensuring a smooth user experience and seamless navigation, connecting design.",
    },
    {
      id: 4,
      image: "./Assets/WebDevelopment/BackendDevelopment.png",
      title: "Back-end Development",
      body: "Back-end development is the backbone of websites and applications, focusing on server-side logic, databases, and application functionality. Programmers use languages like Python, Java, or Node.js to manage data, user authentication, and server operations.",
    },
    {
      id: 5,
      image: "./Assets/WebDevelopment/CodingAndTesting.png",
      title: "Coding And Testing",
      body: "Coding involves writing instructions in programming languages to build software, while testing ensures its functionality and reliability. Programmers write code based on specifications, and testers assess it for bugs and errors.",
    },
    {
      id: 6,
      image: "./Assets/WebDevelopment/Maintenance.png",
      title: "Maintenance",
      body: "Maintenance in software development involves ongoing activities to support, enhance, and optimize a system post-deployment. It includes bug fixes, updates, and adapting to evolving requirements. Regular maintenance ensures software remains secure, performs efficiently.",
    },
  ];
  const handleClick = () => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
  };
  return (
    <div>
      {/* ---------------Section One--------------- */}
      <div
        className="WebDevelopmentTopScreen"
        style={{ backgroundImage: "url('./Assets/UXUI/BackgroundImage.png')" }}
      >
        <div className="WebDevelopmentTopScreenDiv1">
          <h1 className="WebDevelopmenth1">Web Development Servicess</h1>
          <h5 className="WebDevelopmenth5">
            Web development services are crucial for businesses and individuals
            seeking to establish a strong online presence.
          </h5>
          <p className="WebDevelopmentPara">
            GrowCraft professionally designs, redesigns and continuously
            supports customer-facing and enterprise web apps and achieves high
            conversion and adoption rates.
          </p>
          <Link to="/Contact" onClick={handleClick}>
            <button className="Letstalkbtn">Let's talk</button>
          </Link>
        </div>
        <div className="WebDevelopmentTopScreenDiv2">
          <img
            src="./Assets/WebDevelopment/WebDevelopmentTopImage.png"
            className="WebDevelopmentTopImage"
            height={200}
          />
        </div>
      </div>
      {/* ---------------Section Two--------------- */}
      <div className="WebDevelopmentTopScreen2">
        <div className="LineDiv"></div>
        <h1 className="WhatWebh1">What Web Solution Do You Need?</h1>
        <p className="WhatWebPara">
          Web development services help create all types of web-based software
          and ensure great experience for web users. Different types of web
          solutions may seem similar from the outside but we approach them
          differently and know what factors are winning in each case.
        </p>
        <div className="WebSolutionMaindiv">
          <div className="WebSolutiondiv1">
            <img
              src="./Assets/WebDevelopment/WebSolutionImage.png"
              height={500}
              className="WebSolutionImage"
            />
          </div>
          <div className="WebSolutiondiv2">
            {data.map((item, index) => {
              return (
                <div className="WebSolutiondiv212">
                  <div className="backTextDiv">
                    <h5
                      style={{
                        background: item.backColor,
                        color: item.textColor,
                      }}
                      className="backTextColor"
                    >
                      {index + 1}
                    </h5>
                    <h4>{item.title}</h4>
                  </div>
                  <p className="WebSolutionText">{item.body}</p>
                </div>
              );
            })}
          </div>
        </div>
        <div className="LineDiv"></div>
        <h1 className="WhatWebh1">Full-Scale Web Development by</h1>
        <h1 className="GrowCrafth1 WhatWebh1">GrowCraft Solution</h1>
        <p className="WhatWebPara">
          GrowCraft Solution excels in full-scale web development, offering
          end-to-end solutions that encompass everything from conceptualization
          to deployment. Our expert team employs cutting-edge technologies and
          best practices to create tailored web solutions. From responsive
          design to robust back-end systems, we ensure a seamless user
          experience and optimal functionality. Trust GrowCraft for
          comprehensive, innovative web development services.
        </p>
        <div className="GrowCraftMainDiv">
          {data1.map((item, index) => {
            return (
              <div key={index} className="GrowCraftDiv">
                <img src={item.image} height={50} />
                <h4 className="GrowCrafth4">{item.title}</h4>
                <p className="GrowCraftPara">{item.body}</p>
              </div>
            );
          })}
        </div>
        <div className="BoostMainDiv">
          <div className="BoostDiv1">
            <h3>
              Boost your digital potential and impress customers with an
              exceptional web app developed at speed.
            </h3>
            <Link to="/Contact" onClick={handleClick}>
              <button className="ContactBtn">Feel free to talk</button>
            </Link>
          </div>
          <div>
            <img
              src="./Assets/Ecommerce/ReadyImage.png"
              height={200}
              className="ReadyImage"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default WebDevelopment;
