import React from "react";
import "./UXUIStyle.css";
import { Link } from "react-router-dom";

function UXUI() {
  const data = [
    {
      id: 1,
      image: "./Assets/UXUI/UIdesignforwebapplications.png",
      title: "UI design for web applications",
      backgroundColor: "rgba(182, 131, 234, 1)",
      body: "We will create a beautiful and visually-appealing web application. Your site needs to be easy to use and engaging for visitors, and we can help you accomplish this!",
    },
    {
      id: 2,
      image: "./Assets/UXUI/UIdesignforwebapplications.png",
      title: "UI design for mobile apps",
      backgroundColor: "rgba(182, 131, 234, 1)",
      body: "Your app's UI is just as important as your website - if it isn't easy or fun to use, people won't use it. We understand the importance of intuitive app design and can create an engaging UI for your mobile apps.",
    },
    {
      id: 3,
      image: "./Assets/UXUI/UIWireframes.png",
      title: "UI Wireframes",
      backgroundColor: "rgba(246, 77, 0, 1)",
      body: "We will design the layout, structure, and interactivity of your product. Wireframes are like blueprints for a website or app. We can also create interactive prototypes that look exactly like the final version of the product!",
    },
    {
      id: 4,
      image: "./Assets/UXUI/Iconsets&illustrations.png",
      title: "Icon sets & illustrations",
      backgroundColor: "rgba(246, 77, 0, 1)",
      body: "Websites, apps, and software products all require icons to illustrate specific functions; we can create a set of icons that provides a consistent look for your product. Icon sets contribute to a consistent and recognizable design language",
    },
    {
      id: 5,
      image: "./Assets/UXUI/Animationandtransitions.png",
      title: "Animation and transitions",
      backgroundColor: "rgba(255, 192, 70, 1)",
      body: "We can add smooth animation and transitions to your UI design, making your product appear more engaging and visually appealing. This is an excellent way to showcase the interactions users can make in your app!",
    },
    {
      id: 6,
      image: "./Assets/UXUI/UIFrontendDevelopment.png",
      title: "UI Frontend Development",
      backgroundColor: "rgba(255, 192, 70, 1)",
      body: "Orient Software has more than 15 years of experience in creating beautiful and functional front-end applications. We will take your UI/UX design from a static image to a beautiful, interactive web or mobile application.",
    },
  ];
  const data2 = [
    {
      id: 1,
      image: "./Assets/UXUI/Dil1.png",
      title: "Visual Design:",
      body: "Creating visually appealing elements such as graphics, icons, and images to enhance the overall aesthetics of the interface.",
    },
    {
      id: 2,
      image: "./Assets/UXUI/Dil2.png",
      title: "Layout Design:",
      body: "Layout design organizes elements within a space to create visual hierarchy, flow, and structure for optimal user experience and comprehension.",
    },
    {
      id: 3,
      image: "./Assets/UXUI/Dil3.png",
      title: "Color Palette:",
      body: "Choosing an appropriate color scheme that aligns with the brand identity and creates a visually cohesive and pleasing design.",
    },
    {
      id: 4,
      image: "./Assets/UXUI/Dil1.png",
      title: "Typography:",
      body: "Typography shapes written communication, blending fonts, styles, and spacing to convey a message with visual harmony, readability, and aesthetic appeal.",
    },
    {
      id: 5,
      image: "./Assets/UXUI/Dil2.png",
      title: "Responsive Design:",
      body: "Ensuring the UI is adaptable and functions seamlessly across various devices and screen sizes.",
    },
    {
      id: 6,
      image: "./Assets/UXUI/Dil3.png",
      title: "Consistency:",
      body: "Maintaining a consistent design language throughout the interface to create a unified and recognizable brand identity.",
    },
  ];
  const data3 = [
    {
      id: 1,
      image: "./Assets/UXUI/UIdesignforwebapplications.png",
      title: "UX research",
      backgroundColor: "rgba(182, 131, 234, 1)",
      body: "This is the first step in our UX process; we will identify, define and prioritize user goals to determine success for your product. We use various research methods to do this.",
    },
    {
      id: 2,
      image: "./Assets/UXUI/Personas&Scenarios.png",
      title: "Personas & Scenarios",
      backgroundColor: "rgba(182, 131, 234, 1)",
      body: "After understanding your users, we will create realistic user personas based on actual customer data. These are the archetypal persons that represent user types with specific goals and motivations.",
    },
    {
      id: 3,
      image: "./Assets/UXUI/Userflow.png",
      title: "User flow",
      backgroundColor: "rgba(246, 77, 0, 1)",
      body: "After creating several initial user paths, we'll test them out with real customers; if they are successful, we'll iterate until you have a user flow that works.",
    },
    {
      id: 4,
      image: "./Assets/UXUI/Interactiondesign.png",
      title: "Interaction design",
      backgroundColor: "rgba(246, 77, 0, 1)",
      body: "We will work with you to define and implement your product's interaction design. This includes information hierarchy, interactive elements, and smooth transitions between different states in the product's life cycle.",
    },
    {
      id: 5,
      image: "./Assets/UXUI/Animationandtransitions.png",
      title: "Wireframes & prototypes",
      backgroundColor: "rgba(255, 192, 70, 1)",
      body: "These are the blueprint of your website or app - if it doesn't work, then everything will come crashing down around you! We'll create wireframes to illustrate the site structure and page hierarchy which help visitors navigate through your product",
    },
    {
      id: 6,
      image: "./Assets/UXUI/Usabilitytesting.png",
      title: "Usability testing / Heuristic evaluation",
      backgroundColor: "rgba(255, 192, 70, 1)",
      body: "We'll determine if our designs match the initial research and create a set of test scenarios for your product to see what users can do easily and what they can't.",
    },
  ];
  const data4 = [
    {
      id: 1,
      image: "./Assets/UXUI/Dil1.png",
      title: "User Research:",
      body: "User research is the foundation of effective design, uncovering insights into user behaviors, needs, and preferences, ensuring products resonate and deliver exceptional experiences.",
    },
    {
      id: 2,
      image: "./Assets/UXUI/Dil2.png",
      title: "Information Architecture:",
      body: "Organizing and structuring content and features to facilitate easy navigation and information retrieval.",
    },
    {
      id: 3,
      image: "./Assets/UXUI/Dil3.png",
      title: "Wireframing and Prototyping:",
      body: "Creating low-fidelity sketches or interactive prototypes to visualize the product's structure and functionality.",
    },
    {
      id: 4,
      image: "./Assets/UXUI/Dil1.png",
      title: "Usability Testing:",
      body: "Evaluating the product with real users to identify any usability issues and refine the design accordingly.",
    },
    {
      id: 5,
      image: "./Assets/UXUI/Dil2.png",
      title: "Interaction Design:",
      body: "Designing the interactive elements and transitions to enhance user engagement and satisfaction.",
    },
    {
      id: 6,
      image: "./Assets/UXUI/Dil3.png",
      title: "Accessibility:",
      body: "Accessibility is paramount in design, ensuring digital content and interfaces are usable by everyone, regardless of abilities or disabilities, fostering inclusivity and user-friendly experiences.",
    },
  ];
  const handleClick = () => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
  };
  return (
    <div>
      {/* ---------------Section One--------------- */}
      <div
        className="UXUITopScreen"
        style={{ backgroundImage: "url('./Assets/UXUI/BackgroundImage.png')" }}
      >
        <div className="UXUITopScreenDiv1">
          <h1 className="UXUIh1">UI/UX Design Services</h1>
          <h5 className="UXUIh5">
            Elevate Your Digital Presence with Exceptional UI/UX Design
            Services: Crafting Intuitive and Engaging User Experiences for
            Seamless Interaction
          </h5>
          <p className="UXUIPara">
            At GrowCraft Solution, we redefine digital experiences through our
            expert UI/UX Design Services. Our dedicated team combines creativity
            and functionality to craft visually stunning and intuitively
            navigable interfaces. From user research to prototyping, we ensure
            every detail contributes to an engaging user journey. Elevate your
            brand's online presence with our UI/UX expertise, where design meets
            user satisfaction. Let's create experiences that leave a lasting
            impression.
          </p>
          <Link to="/Contact" onClick={handleClick}>
            <button className="Letstalkbtn">Let's talk</button>
          </Link>
        </div>
        <div className="UXUITopScreenDiv2">
          <img
            src="./Assets/UXUI/UXUITopImage.png"
            height={350}
            className="UXUITopImage"
          />
        </div>
      </div>
      {/* ---------------Section Two--------------- */}
      <div className="UXUITopScreen2">
        <div className="UXUITopScreen2Div1">
          <img src="./Assets/UXUI/Talktous.png" height={300} />
        </div>
        <div className="UXUITopScreen2Div2">
          <h1 className="UIandUXdesignh1">
            WHAT EXACTLY ARE UI/UX DESIGN SERVICES?
          </h1>
          <p className="UIandUXdesign ServicesPara">
            UI and UX design is the process of maximizing user satisfaction by
            improving the usability and accessibility of a web or mobile
            application. The goal is to enhance the experience for the users
            with visual design elements while optimizing usability and
            accessibility for their users. A well-designed product not only
            looks great and stylish but has a clear UI, intuitive navigation,
            and easily accessible features which provide a smooth and efficient
            user experience.
          </p>
          <Link to="/Contact" onClick={handleClick}>
            <button className="Talktousbtn">Talk to us</button>
          </Link>
        </div>
      </div>
      {/* ---------------Section Three--------------- */}
      <div className="UXUITopScreen3">
        <div className="CircleDot">
          <div className="CircleDiv CircleDiv1"></div>
          <img
            src="./Assets/UXUI/Dot1.png"
            height={80}
            className="Dot1 Dot11"
          />
        </div>
        <div className="UXUITopScreen3Contact">
          <div className="OURUIDESIGNDiv">
            <h1 className="SERVICESh1">OUR UI DESIGN</h1>
            <h1 className="SERVICES1 SERVICESh1">SERVICES</h1>
          </div>
          <p className="ServicesPara">
            UI (User Interface) design services focus on enhancing the visual
            aspects of a digital product to create a visually appealing and
            aesthetically pleasing user interface. UI design is concerned with
            the look and feel of the product, incorporating elements like color
            schemes, typography, iconography, and overall layout. The goal is to
            make the user interface visually engaging, intuitive, and easy to
            navigate. UI design plays a crucial role in attracting users,
            establishing brand identity, and ensuring a positive first
            impression.
          </p>
          <h6>Our UI services include:</h6>
          <div className="UIservicesincludeMainDiv">
            {data.map((item, index) => {
              return (
                <div className="UIservicesincludeDiv" key={index}>
                  <div
                  // className="UIservicesincludeImageDiv"
                  // style={{ background: item.backgroundColor }}
                  >
                    <img
                      src={item.image}
                      className="UIservicesincludeImage"
                      style={{ background: item.backgroundColor }}
                    />
                  </div>
                  <div>
                    <h4 className="Servicesh4">{item.title}</h4>
                    <p className="ServicesPara">{item.body}</p>
                  </div>
                </div>
              );
            })}
          </div>
          <h6>Key aspects of UI design services include:</h6>
          <div className="KeyaspectsofUIdesignDiv">
            {data2.map((item, index) => {
              return (
                <div className="KeyaspectsofUIdesign" key={index}>
                  <div>
                    <img src={item.image} height={15} className="dilDiv" />
                  </div>
                  <div className="KeyaspectsofUIdesignText">
                    <h5
                      className="KeyaspectsofUIdesignh5"
                      style={{
                        width:
                          item.title == "Layout Design"
                            ? 160
                            : item.title == "Typography"
                            ? 140
                            : null,
                      }}
                    >
                      {item.title}
                    </h5>
                    <p className="ServicesPara"> {item.body}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="CircleDot">
          <img src="./Assets/UXUI/Dot2.png" height={80} className="Dot11" />
          <div className="CircleDiv2 CircleDiv1"></div>
        </div>
        <div
          className="CollaborationImage"
          style={{ backgroundImage: "url('./Assets/UXUI/Collaboration.png')" }}
        >
          <h1 className="CollaborationText Collaborationh1">
            COLLABORATION BETWEEN DESIGNERS AND DEVELOPERS
          </h1>
          <p className="CollaborationText Collaborationpara">
            Collaboration between designers and developers is vital for
            successful project outcomes. Designers bring creativity, and
            developers implement functionality, ensuring a cohesive,
            user-centric, and technically feasible product. Effective
            communication and mutual understanding foster synergy.
          </p>
          <p className="CollaborationText">
            At GrowCraft solution, the designers and developers work on the same
            team, which helps improve communication and productivity throughout
            the process. For example, when developers are involved in the design
            process right from the beginning, it’s easier for them to understand
            the purpose behind each decision.
          </p>
          <p>
            Good communication between designers and developers means that both
            can provide feedback on how an interface behaves or what needs to be
            changed before development starts. It helps avoid unnecessary
            back-and-forth throughout the process, which means that the
            designers are more likely to hit deadlines and the developers are
            more likely to create stable features.
          </p>
        </div>
        <img src="./Assets/UXUI/Dot3.png" height={80} className="Dot11" />
        <div className="UXUITopScreen3Contact">
          <div className="OURUIDESIGNDiv">
            <h1 className="SERVICESh1">OUR UX DESIGN</h1>
            <h1 className="SERVICES1 SERVICESh1">SERVICES</h1>
          </div>
          <p className="ServicesPara">
            Our UX (User Experience) Design Services prioritize user
            satisfaction. From research to prototyping, we craft seamless,
            intuitive experiences, enhancing digital products for optimal
            engagement and success. We can help with the design process from
            beginning to end. So whether you need a full-scale redesign or just
            some tweaks here and there, we’ll be with you every step of the way.
          </p>
          <h6>Our UX services include:</h6>
          <div className="UIservicesincludeMainDiv">
            {data3.map((item, index) => {
              return (
                <div className="UIservicesincludeDiv" key={index}>
                  <div
                  // className="UIservicesincludeImageDiv"
                  // style={{ background: item.backgroundColor }}
                  >
                    <img
                      src={item.image}
                      className="UIservicesincludeImage"
                      style={{ background: item.backgroundColor }}
                    />
                  </div>
                  <div>
                    <h4 className="Servicesh4">{item.title}</h4>
                    <p className="ServicesPara">{item.body}</p>
                  </div>
                </div>
              );
            })}
          </div>
          <h6>Key aspects of UX design services include:</h6>
          <div className="KeyaspectsofUIdesignDiv">
            {data4.map((item, index) => {
              return (
                <div className="KeyaspectsofUIdesign" key={index}>
                  <div>
                    <img src={item.image} height={15} className="dilDiv" />
                  </div>
                  <div className="KeyaspectsofUIdesignText">
                    <h5
                      className="KeyaspectsofUIdesignh5"
                      style={{
                        width:
                          item.title == "User Research"
                            ? 200
                            : item.title == "Accessibility"
                            ? 180
                            : null,
                      }}
                    >
                      {item.title}
                    </h5>
                    <p className="ServicesPara"> {item.body}</p>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="WebDesignDiv">
            <h1 className="WebDesignDivh1 SERVICESh1">
              Why You Should Invest in Web Design 
            </h1>
            <p className="WebDesignDivh1 ServicesPara">
              Almost 79% of users say that the combination of a web solution’s
              look, feel, and performance defines their impression of a company
              behind it. In other words, whether you make sales via the internet
              or not, your online representation is critical to the success of
              your business. By investing in web design, you invest directly in
              your commercial future. Investing in web design is an investment
              in your online presence, brand reputation, and the overall success
              of your business in the digital age. A well-designed website can
              positively impact user experience, search engine rankings, and
              conversion rates, ultimately contributing to the growth and
              sustainability of your business.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UXUI;
