
import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Header.css";

function Header() {
  const [activeLink, setActiveLink] = useState(""); // State to track active link

  const handleClick = (link) => {
    setActiveLink(link); // Update active link state
    window.scrollTo(0, 0); // Scroll to the top of the page
  };

  return (
    <nav className="navbar navbar-expand-lg fixed-top header" id="HeaderBar">
      <div className="container">
        <Link className="navbar-brand" to="/" onClick={handleClick}>
          <img src="./Assets/Header/logo1.png" height="40" />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="navbar-collapse collapse navHeading" id="navbarSupportedContent">
          <div></div>
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link
                className={`contactusText1 ${activeLink === "home" ? "active" : ""}`}
                to="/"
                onClick={() => handleClick("home")}
              >
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`contactusText1 ${activeLink === "about" ? "active" : ""}`}
                to="/AboutUs"
                onClick={() => handleClick("about")}
              >
                About Us
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`contactusText1 ${activeLink === "services" ? "active" : ""}`}
                to="/Service"
                onClick={() => handleClick("services")}
              >
                Services
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`contactusText1 ${activeLink === "projects" ? "active" : ""}`}
                to="/Project"
                onClick={() => handleClick("projects")}
              >
                Our Projects
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`contactusText1 ${activeLink === "career" ? "active" : ""}`}
                to="/Career"
                onClick={() => handleClick("career")}
              >
                Career
              </Link>
            </li>
          </ul>
          <div className="d-flex me-1 contactusDiv">
            <Link
              className={`contactusText1 ${activeLink === "contact" ? "active" : ""}`}
              to="/Contact"
              onClick={() => handleClick("contact")}
            >
              Contact Us
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Header;

