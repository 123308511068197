import React from "react";
import Header from "../Components/Header/Header";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Footer from "../Components/Footer/Footer";
import Home from "../Screens/HomeScreen/Home";
import AboutUs from "../Screens/AboutUsScreen/AboutUs";
import Service from "../Screens/ServicesScreen/Service";
import Project from "../Screens/OurProjectScreen/Project";
import Career from "../Screens/CareerScreen/Career";
import Contact from "../Screens/ContactUsScreen/Contact";
import UXUI from "../Screens/UXUIScreen/UXUI";
import WebDevelopment from "../Screens/WebDevelopmentScreen/WebDevelopment";
import MobileApp from "../Screens/MobileAppScreen/MobileApp";
import Ecommerce from "../Screens/EcommerceScreen/Ecommerce";
import FullStack from "../Screens/FullStackScreen/FullStack";

const Root = () => {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/AboutUs" element={<AboutUs />} />
        <Route exact path="/Service" element={<Service />} />
        <Route exact path="/Project" element={<Project />} />
        <Route exact path="/Career" element={<Career />} />
        <Route exact path="/Contact" element={<Contact />} />
        <Route exact path="/UXUI" element={<UXUI />} />
        <Route exact path="/WebDevelopment" element={<WebDevelopment />} />
        <Route exact path="/MobileApp" element={<MobileApp />} />
        <Route exact path="/Ecommerce" element={<Ecommerce />} />
        <Route exact path="/FullStack" element={<FullStack />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default Root;
