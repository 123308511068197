import React, { useState } from "react";
import "./ContactStyle.css";
import { Link } from "react-router-dom";
import swal from "sweetalert";

function Contact() {
  const handleSubmit = () => {
    swal(
      "Thanks for reaching out!",
      "We're excited to talk to you and will reply as soon as we can",
      "success"
    );
  };
  const [value, setValue] = useState("");

  const handleChange = (event) => {
    // Limit the input length to 10 characters
    const inputValue = event.target.value.slice(0, 10);
    setValue(inputValue);
  };
  return (
    <div>
      {/* ---------------Section One--------------- */}
      <div className="ContactTopScreen">
        <div className="ContactTopScreenDiv1">
          <img src="./Assets/Contact/dot1.png" height={20} />
          <div className="Linediv">
            <div className="Line"></div>
            <h5 className="Lineh5">DO YOU HAVE ANY QUESTION</h5>
          </div>
          <h1 className="ContactTopScreenh1 ContactTopScreenh11">Contact Us</h1>
          <p className="ContactTopScreenh1 ContactTopScreenPara">
            Got a project in mind? We’d love to hear about it. Take five minutes
            to fill out our project form so that we can get to know you and
            understand your project.
          </p>
        </div>
        <div className="ContactTopScreenDiv2">
          <div className="ScreentopImageDiv">
            <img
              src="./Assets/Contact/ContactIcon.png"
              height={350}
              className="ScreentopImage"
            />
          </div>
        </div>
      </div>
      {/* ---------------Section Two--------------- */}
      <div className="ContactTopScreen2">
        <div className="Linediv1">
          <div className="Line1"></div>
          <h5 className="Lineh5">WORK WITH US</h5>
        </div>
        <h2 className="Leth2">Let build something awesome together</h2>
        <div className="ContactdetailsMainDiv">
          <div className="ContactdetailsDiv1">
            <div className="contactCardDiv">
              <div className="contactCardHeader">
                <h4>Contact Details</h4>
              </div>
              <div className="contactCardBody">
                <h5>Office Address</h5>
                <div className="LocationDiv">
                  <img
                    src="./Assets/Contact/Location.png"
                    height={20}
                    className="LocationImage"
                  />
                  <p className="addressPara">
                    5th floor, Office No. 508, NRK Biz Park, Sch. No. 54, Vijay
                    Nagar Indore, Madhya Pradesh (452010)
                  </p>
                </div>
                <hr />
                <h5>Contact Info</h5>
                <div className="LocationDiv">
                  <img
                    src="./Assets/Contact/Call.png"
                    height={20}
                    className="LocationImage"
                  />
                  <p className="addressPara"> (+91) 9516327187</p>
                </div>
                <div className="LocationDiv">
                  <img
                    src="./Assets/Contact/Mail.png"
                    height={20}
                    className="LocationImage"
                  />
                  <p className="addressPara">
                    {" "}
                    contactus@growcraftsolution.com
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="ContactdetailsDi2">
            <form
              action="https://formsubmit.co/00a5303ff33bde7076c9f1af62ce1c95"
              method="POST"
              onSubmit={handleSubmit}
            >
              {/* Honeypot */}
              <input type="text" name="_honey" style={{ display: "none" }} />

              {/* Disable Captch */}
              <input type="hidden" name="_captcha" value="false" />

              <input
                type="hidden"
                name="_next"
                value="https://growcraftsolution.com/Contact"
              />

              <table className="tableDiv">
                <tr className="tableDivtr">
                  <td className="NameTD">
                    Full Name <br />
                    <input
                      type="text"
                      placeholder="Enter your name"
                      className="inputField"
                      name="Full&nbsp;Name"
                      required
                    />
                  </td>
                  <td>
                    Email Address <br />
                    <input
                      type="email"
                      placeholder="Enter your email"
                      className="inputField"
                      name="Email&nbsp;Address"
                      required
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    Phone Number <br />
                    {/* <input
                      type="number"
                      placeholder="Enter your number"
                      className="inputField"
                      name="Mobile&nbsp;Number"
                      required
                    /> */}
                    <input
                      type="number"
                      placeholder="Enter your number"
                      className="inputField"
                      name="Mobile Number"
                      value={value}
                      onChange={handleChange}
                      required
                    />
                  </td>
                  <td>
                    Subject <br />
                    <input
                      type="text"
                      placeholder="Enter your subject"
                      className="inputField"
                      name="Subject"
                      required
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={2} className="MessageTD">
                    Message <br />
                    <textarea
                      rows={4}
                      cols={57}
                      className="textAreaField"
                      placeholder="Enter your message..."
                      name="Message"
                      required
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    {/* <input className="submitbtn" type="submit"/>Submit */}
                    <button className="submitbtn" type="submit">
                      Submit
                    </button>
                  </td>
                </tr>
              </table>
            </form>
          </div>
        </div>
      </div>
      {/* ---------------Section Three--------------- */}
      <div className="ContactTopScreen3">
        <Link
          to={
            "https://www.google.com/maps/place/GrowCraft+Solution/@22.7459566,75.8957941,15.27z/data=!4m6!3m5!1s0x6937843fb031ee23:0xaed47f5b4042b350!8m2!3d22.7457595!4d75.896167!16s%2Fg%2F11vt0skycx?authuser=3&entry=ttu"
          }
        >
          <img
            src="./Assets/Contact/companyLocation.png"
            alt="Location"
            className="companyLocationImage"
          />
        </Link>
      </div>
    </div>
  );
}

export default Contact;
